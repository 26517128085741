import React, { FunctionComponent } from "react";
import { useForm } from "react-hook-form";
import { Button, Form, FormGroup, FormText, Input, Label } from "reactstrap";
import Lock from "../../assets/images/icons/lock.svg";
import logoDark from "../../assets/images/logo_dark.svg";
import { Icon } from "../../components/Icon/Icon";
import { useAuthService } from "../../services/useAuthService";
import { ILoginCredentials } from "../../types/credentials";
import "./Login.scss";

export const Login: FunctionComponent = () => {
  const { register, handleSubmit } = useForm();
  const { login } = useAuthService();

  const onSubmit = async (data: any) => {
    const { success } = await login(data as ILoginCredentials);

    if (success) {
      window.location.replace("/overview");
    }
  };

  return (
    <div className="vh-100 d-flex justify-content-center align-items-center">
      <div className="Login p-5 Login__form border border-light">
        <div className="w-100 d-flex align-items-center justify-content-between mb-5 marginfix">
          <img
            src={logoDark}
            height="14"
            className="d-inline-block"
            alt="Fastly logo"
          />
          <Icon image={Lock} />
        </div>
        <div>
          <Form className="" onSubmit={handleSubmit(onSubmit)}>
            <FormGroup>
              <Label for="email">E-post</Label>
              <Input type="email" name="email" id="email" innerRef={register} />
            </FormGroup>
            <FormGroup>
              <Label for="password">Lösenord</Label>
              <Input
                type="password"
                name="password"
                id="password"
                innerRef={register}
              />
            </FormGroup>
            <Button
              type="submit"
              size="lg "
              color="black"
              block
              className="mt-5 mb-5"
            >
              Logga in
            </Button>
            <FormText>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Pellentesque ac sollicitudin orci.
            </FormText>
          </Form>
        </div>
      </div>
    </div>
  );
};
