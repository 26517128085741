import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Column } from "react-table";
import { Container } from "reactstrap";
import buildingIcon from "../../assets/images/icons/building.svg";
import { FastlyTable } from "../../components/Table/FastlyTable";
import { useBuildingService } from "../../services/useBuildingService";
import { IBuildingResponse } from "../../types/building";
import { IRequestResponse } from "../../utils/api";

const buildingsColumns: Column<IBuildingResponse>[] = [
  {
    Header: "Adress",
    accessor: "address",
  },
  {
    Header: "Antal hyresobjekt",
    accessor: "rental_count",
  },
];

export const Buildings = () => {
  const history = useHistory();
  const { fetchBuildings } = useBuildingService();
  const [buildings, setBuildings] = useState({
    loaded: false,
    data: [],
  } as IRequestResponse<IBuildingResponse[]>);

  useEffect(() => {
    (async () => {
      setBuildings(await fetchBuildings());
    })();
  }, [fetchBuildings]);

  const onTableRowClick = (data: any) => {
    history.push(`/buildings/${data.id}`);
  };

  return (
    <div>
      <Container>
        <h2 className="mb-4">Byggnader</h2>
        <FastlyTable<IBuildingResponse>
          loaded={buildings.loaded}
          columns={buildingsColumns}
          data={buildings.data}
          onTableRowClick={onTableRowClick}
          icon={buildingIcon}
        />
      </Container>
    </div>
  );
};
