export enum RecentType {
  Application = "application",
  Report = "report",
}

export interface IOverviewResponse {
  apartments: number;
  buildings: number;
  properties: number;
  residents: number;
  total_rent: number;
}

export interface IRecentReportResponse {
  type: RecentType.Report;
  id: string;
  rental: {
    id: string;
    slug: string;
  };
  information: string;
  date: string;
}

export interface IRecentApplicationResponse {
  type: RecentType.Application;
  id: string;
  name: string;
  information: string;
  date: string;
}

export type IRecentResponse = IRecentApplicationResponse &
  IRecentApplicationResponse;
