import { useCallback, useContext } from "react";
import { ApiContext } from "../context/api";
import { IDetailedRentalResponse, IRentalResponse } from "../types/rental";
import { ApiRequestMethod, clientApiRequestService } from "../utils/api";

export const useRentalService = () => {
  const { client } = useContext(ApiContext);

  const fetchAvailableRentals = () =>
    clientApiRequestService<IRentalResponse[]>({
      client,
      method: ApiRequestMethod.Get,
      path: "/rentals/available",
    });

  const exportRentals = () =>
    clientApiRequestService({
      client,
      method: ApiRequestMethod.Get,
      path: `/rentals/export`,
    });

  const importRentals = (data: any) =>
    clientApiRequestService({
      client,
      method: ApiRequestMethod.Post,
      path: `/rentals/export`,
      data,
    });

  const addResidentToRental = (
    residentId: string,
    rentalId: string,
    data: any
  ) =>
    clientApiRequestService<IDetailedRentalResponse>({
      client,
      method: ApiRequestMethod.Post,
      path: `/residents/${residentId}/rentals/${rentalId}`,
      data,
    });

  const updateResidentRentalRelation = (
    residentId: string,
    rentalId: string,
    data: any
  ) =>
    clientApiRequestService({
      client,
      method: ApiRequestMethod.Put,
      path: `/residents/${residentId}/rentals/${rentalId}`,
      data,
    });

  const removeRentalFromResident = (residentId: string, rentalId: string) =>
    clientApiRequestService<IDetailedRentalResponse>({
      client,
      method: ApiRequestMethod.Delete,
      path: `/residents/${residentId}/rentals/${rentalId}`,
    });

  const setResidentAsActive = (rentalId: string, residentId: string) =>
    clientApiRequestService({
      client,
      method: ApiRequestMethod.Put,
      path: `/rentals/${rentalId}`,
      data: { resident_id: residentId },
    });

  return {
    fetchAvailableRentals: useCallback(fetchAvailableRentals, []),
    exportRentals: useCallback(exportRentals, []),
    importRentals: useCallback(importRentals, []),
    addResidentToRental: useCallback(addResidentToRental, []),
    updateResidentRentalRelation: useCallback(updateResidentRentalRelation, []),
    removeRentalFromResident: useCallback(removeRentalFromResident, []),
    setResidentAsActive: useCallback(setResidentAsActive, []),
  };
};
