import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { RouteComponentProps } from "react-router-dom";
import { Column } from "react-table";
import {
  Badge,
  Button,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  PopoverBody,
  Row,
  UncontrolledPopover,
} from "reactstrap";
import infoIcon from "../../assets/images/icons/info.svg";
import { Icon } from "../../components/Icon/Icon";
import { LoaderInput } from "../../components/LoaderInput/LoaderInput";
import { FastlyTable } from "../../components/Table/FastlyTable";
import { useApplicationService } from "../../services/useApplicationService";
import {
  IDetailedNotificationResponse,
  ISimpleApplicationResponse,
} from "../../types/application";
import { IRequestResponse } from "../../utils/api";
import { toPrettyDate } from "../../utils/fields";

interface NotificationParams {
  notificationId: string;
}

const applicationColumns: Column<ISimpleApplicationResponse>[] = [
  {
    Header: "Namn",
    accessor: "fullname",
  },
  {
    Header: "E-post",
    accessor: "email",
  },
  {
    Header: "Telefon",
    accessor: "phone",
  },
  {
    accessor: "accepted",
    Cell: ({ row }) =>
      row.original.accepted && <Badge color="success">Tackat ja!</Badge>,
  },
  {
    accessor: "information",
    Cell: ({ row }) =>
      row.original.information && (
        <>
          <Icon id={`popover-${row.original.id}`} image={infoIcon} />
          <UncontrolledPopover
            trigger="hover"
            placement="left"
            target={`popover-${row.original.id}`}
          >
            <PopoverBody>{row.original.information}</PopoverBody>
          </UncontrolledPopover>
        </>
      ),
  },
  {
    id: "order",
    accessor: (d) => (d.accepted ? 1 : 0),
  },
];

export const Notification: React.FC<RouteComponentProps<
  NotificationParams
>> = ({ match: { params } }) => {
  const { register, handleSubmit } = useForm();
  const {
    fetchDetailedNotification,
    closeNotification,
  } = useApplicationService();
  const [notification, setNotification] = useState({
    loaded: false,
    data: {},
  } as IRequestResponse<IDetailedNotificationResponse>);

  const memoizedNotification = useMemo(
    () =>
      notification.loaded
        ? {
            slug: notification.data.apartment.slug,
            active: notification.data.active,
            date: toPrettyDate(notification.data.created),
          }
        : {},
    [notification.data, notification.loaded]
  );

  const memoizedApplications = useMemo(
    () => (notification.loaded ? notification.data.applications : []),
    [notification.data.applications, notification.loaded]
  );

  useEffect(() => {
    (async () => {
      setNotification(await fetchDetailedNotification(params.notificationId));
    })();
  }, [fetchDetailedNotification, params.notificationId]);

  const onClose = async (data: any) => {
    const response = await closeNotification(params.notificationId, data);

    if (response.success) {
      setNotification({
        ...notification,
        data: {
          ...notification.data,
          active: false,
        },
      });
    }
  };

  return (
    <>
      <Container>
        <h2>Utskick</h2>
        <Form className="mt-5 mb-5" onSubmit={handleSubmit(onClose)}>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label for="apartmentSlug">Namn</Label>
                <LoaderInput
                  defaultValue={memoizedNotification.slug}
                  loaded={notification.loaded}
                  readOnly
                  disabled
                  type="text"
                  name="name"
                  id="apartmentSlug"
                  plaintext
                />
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup>
                <Label for="notificationDate">Skapad</Label>
                <LoaderInput
                  loaded={notification.loaded}
                  defaultValue={memoizedNotification.date}
                  readOnly
                  disabled
                  type="text"
                  name="ssn"
                  id="notificationDate"
                  plaintext
                />
              </FormGroup>
            </Col>
            <Col md={3}>
              <Label for="notificationStatus">Status</Label>
              {notification.loaded && (
                <FormGroup>
                  <Badge
                    color={memoizedNotification.active ? "success" : "danger"}
                  >
                    {memoizedNotification.active ? "Pågående" : "Avslutad"}
                  </Badge>
                </FormGroup>
              )}
            </Col>
          </Row>
          {notification.data.active && (
            <>
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label for="applicationId">Hyresgäst</Label>
                    <Input
                      type="select"
                      name="application_id"
                      id="applicationId"
                      innerRef={register}
                    >
                      <option value="">Ingen hyresgäst</option>
                      {memoizedApplications
                        .filter((application) => application.accepted)
                        .map(({ id, fullname }) => (
                          <option key={id} value={id}>
                            {fullname}
                          </option>
                        ))}
                    </Input>
                  </FormGroup>
                </Col>
                <Col md={3}>
                  <FormGroup>
                    <Label for="residentPaymentReference">
                      Betalningsreferens
                    </Label>
                    <LoaderInput
                      loaded={notification.loaded}
                      type="text"
                      name="payment_reference"
                      id="residentPaymentReference"
                      innerRef={register}
                    />
                  </FormGroup>
                </Col>
                <Col md={3}>
                  <FormGroup>
                    <Label for="residentMoveIn">Inflyttning</Label>
                    <LoaderInput
                      loaded={notification.loaded}
                      type="date"
                      name="valid_from"
                      id="residentMoveIn"
                      placeholder="Inflyttningsdatum"
                      innerRef={register}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Button color="primary">Spara och avsluta</Button>
                  </FormGroup>
                </Col>
              </Row>
            </>
          )}
        </Form>
      </Container>
      <Container>
        <h2>Intresseanmälningar</h2>
        <FastlyTable<ISimpleApplicationResponse>
          loaded={notification.loaded}
          columns={applicationColumns}
          data={memoizedApplications}
          initialState={{
            sortBy: [{ id: "order", desc: true }],
            hiddenColumns: ["order"],
          }}
        />
      </Container>
    </>
  );
};
