import React from "react";
import { Col, Container, Row } from "reactstrap";
import logo_dark from "../../assets/images/logo_dark.svg";
import "./Footer.scss";
import overviewillustration from "../../assets/images/overview_illustration_hz.svg";

export const Footer = () => {
  return (
    <div className="mt-5">
      <Container className="footer py-5">
        <Row>
        <img
            src={overviewillustration}
            width="100%"
            className="d-inline-block"
            alt="Fastly logo"
          />
        </Row>
        <Row className="mt-4">
          <Col xs={6}>
            <img
              src={logo_dark}
              height="12"
              className="d-inline-block"
              alt="Fastly logo"
            />
          </Col>
          <Col xs={6}>
            <div className="d-flex justify-content-end">
              <small className="text-muted font-weight-bold">Om</small>
              <small className="text-muted ml-4 font-weight-bold">
                Villkor
              </small>
              <small className="text-muted ml-4 font-weight-bold">
                Personuppgifter
              </small>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
