import React, { useState } from "react";
import { NavLink as RRNavLink } from "react-router-dom";
import { Collapse, Container, Nav, Navbar, NavItem, NavLink } from "reactstrap";
import apartmentIcon from "../../assets/images/icons/apartment.svg";
import buildingIcon from "../../assets/images/icons/building.svg";
import costIcon from "../../assets/images/icons/cost.svg";
import damagesIcon from "../../assets/images/icons/damages.svg";
import listIcon from "../../assets/images/icons/list.svg";
import menuIcon from "../../assets/images/icons/menu.svg";
import personIcon from "../../assets/images/icons/person.svg";
import propertyIcon from "../../assets/images/icons/property.svg";
import settingsIcon from "../../assets/images/icons/settings.svg";
import speculantIcon from "../../assets/images/icons/speculant.svg";
import exportIcon from "../../assets/images/icons/export.svg";
import { Icon } from "../Icon/Icon";
import "./Menu.scss";

export const Menu = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <Navbar light expand="lg" className="p-0">
      <Container className="p-0">
        <div
          onClick={toggle}
          className="mb-5 toggle d-lg-none p-3 border border-light w-100"
        >
          <Icon image={menuIcon} />
          <span className="ml-2 font-weight-bold">Meny</span>
        </div>
        <Collapse isOpen={isOpen} navbar>
          <Nav className="mr-auto w-100 mb-5" navbar vertical>
            <NavItem>
              <NavLink activeClassName="active" tag={RRNavLink} to="/overview">
                <Icon image={listIcon} />
                <span className="ml-4">Översikt</span>
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink activeClassName="active" tag={RRNavLink} to="/properties">
                <Icon image={propertyIcon} />
                <span className="ml-4">Fastigheter</span>
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink activeClassName="active" tag={RRNavLink} to="/buildings">
                <Icon image={buildingIcon} />
                <span className="ml-4">Byggnader</span>
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink activeClassName="active" tag={RRNavLink} to="/apartments">
                <Icon image={apartmentIcon} />
                <span className="ml-4">Lägenheter</span>
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink activeClassName="active" tag={RRNavLink} to="/residents">
                <Icon image={personIcon} />
                <span className="ml-4">Hyreskontrakt</span>
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink activeClassName="active" tag={RRNavLink} to="/addons">
                <Icon image={costIcon} />
                <span className="ml-4">Tillägg</span>
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink activeClassName="active" tag={RRNavLink} to="/applications">
                <Icon image={speculantIcon} />
                <span className="ml-4">Intresseanmälningar</span>
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink activeClassName="active" tag={RRNavLink} to="/reports">
                <Icon image={damagesIcon} />
                <span className="ml-4">Felanmälning</span>
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink activeClassName="active" tag={RRNavLink} to="/settings">
                <Icon image={settingsIcon} />
                <span className="ml-4">Inställningar</span>
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink activeClassName="active" tag={RRNavLink} to="/export">
                <Icon image={exportIcon} />
                <span className="ml-4">Export / Import</span>
              </NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
};
