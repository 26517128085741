import { IDetailedRentalResponse } from "./rental";

export enum ResidentRentalStatus {
  Created = "created",
  Active = "active",
  Upcoming = "upcoming",
  Outgoing = "outgoing",
  Inactive = "inactive",
}

export interface IPersonResponse {
  fullname: string;
  firstname: string;
  lastname: string;
  email: string;
  phone: string;
  ssn: string;
  id: string;
}

export interface IResidentResponse {
  id: string;
  payment_reference: string;
  status: ResidentRentalStatus;
  persons: IPersonResponse[];
  valid_from: string;
  valid_to: string;
}

export interface IDetailedResidentResponse extends IResidentResponse {
  rentals: IDetailedRentalResponse[];
}
