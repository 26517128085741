import { IResidentResponse, ResidentRentalStatus } from "../types/resident";

export interface IResident {
  id: string;
  fullname: string;
  phone: string;
  email: string;
  status: ResidentRentalStatus;
  valid_from: string;
  valid_to: string;
}

export const residentsSelector = (residents: IResidentResponse[]) =>
  residents.reduce<IResident[]>(
    (previousValue, currentValue) => [
      ...previousValue,
      ...currentValue.persons.map((person) => ({
        id: currentValue.id,
        fullname: person.fullname,
        phone: person.phone,
        email: person.email,
        valid_from: currentValue.valid_from,
        valid_to: currentValue.valid_to,
        status: currentValue.status,
      })),
    ],
    []
  );
