import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { Column } from "react-table";
import {
  Badge,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  InputGroup,
  InputGroupButtonDropdown,
  Label,
  Row,
} from "reactstrap";
import propertyIcon from "../../assets/images/icons/property.svg";
import { FoldoutForm } from "../../components/FoldoutForm/FoldoutForm";
import { FastlyTable } from "../../components/Table/FastlyTable";
import { usePropertyService } from "../../services/usePropertyService";
import { useSettingService } from "../../services/useSettingService";
import { IAreaResponse } from "../../types/area";
import { IPropertyResponse } from "../../types/property";
import { IRequestResponse } from "../../utils/api";

const propertiesColumns: Column<IPropertyResponse>[] = [
  {
    Header: "Beteckning",
    accessor: "title",
  },
  {
    Header: "Ort",
    accessor: "city",
  },
  {
    Header: "Område",
    accessor: "area",
  },
  {
    accessor: "status",
    Cell: ({ row }) =>
      row.values.status === "created" && <Badge color="success">Ny</Badge>,
  },
];

export const Properties = () => {
  const { fetchProperties, createProperty } = usePropertyService();
  const { fetchAreas } = useSettingService();
  const { register, handleSubmit, setValue, reset } = useForm();
  const history = useHistory();
  const [dropDownIsOpen, setDropDownIsOpen] = useState(false);
  const [properties, setProperties] = useState({
    loaded: false,
    data: [],
  } as IRequestResponse<IPropertyResponse[]>);
  const [areas, setAreas] = useState<IRequestResponse<IAreaResponse[]>>({
    loaded: false,
    data: [],
  });

  const memoizedProperties = useMemo(() => properties.data, [properties]);

  useEffect(() => {
    (async () => {
      setProperties(await fetchProperties());
    })();
  }, [fetchProperties]);

  useEffect(() => {
    (async () => {
      setAreas(await fetchAreas());
    })();
  }, [fetchAreas]);

  const onSubmit = async (data: any) => {
    const createdPropertyResponse = await createProperty(data);

    if (createdPropertyResponse.success) {
      setProperties({
        loaded: true,
        data: [createdPropertyResponse.data, ...properties.data],
      });

      reset();
    }
  };

  const onTableRowClick = (data: any) => {
    history.push(`/properties/${data.id}`);
  };

  return (
    <div>
      <FoldoutForm
        title="Fastigheter"
        buttonText="Lägg till"
        onSubmit={handleSubmit(onSubmit)}
      >
        <FormGroup row>
          <Col>
            <Label for="propertyTitle">Beteckning</Label>
            <Input
              type="text"
              name="title"
              id="propertyTitle"
              innerRef={register({ required: true })}
            />
          </Col>
        </FormGroup>
        <Row form>
          <Col md={6}>
            <FormGroup>
              <Label for="propertyCity">Ort</Label>
              <Input
                type="text"
                name="city"
                id="propertyCity"
                innerRef={register({ required: true })}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="propertyArea">Område</Label>
              <InputGroup>
                <Input
                  type="text"
                  name="area_name"
                  id="propertyArea"
                  innerRef={register({ required: true })}
                />
                <InputGroupButtonDropdown
                  addonType="append"
                  isOpen={dropDownIsOpen}
                  toggle={() => setDropDownIsOpen(!dropDownIsOpen)}
                >
                  <DropdownToggle caret color="primary" />
                  <DropdownMenu>
                    {areas.data.map((area) => (
                      <DropdownItem
                        key={area.id}
                        onClick={() => setValue("area_name", area.name)}
                      >
                        {area.name}
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </InputGroupButtonDropdown>
              </InputGroup>
            </FormGroup>
          </Col>
        </Row>
      </FoldoutForm>
      <Container>
        <FastlyTable<IPropertyResponse>
          loaded={properties.loaded}
          columns={propertiesColumns}
          data={memoizedProperties}
          onTableRowClick={onTableRowClick}
          icon={propertyIcon}
        />
      </Container>
    </div>
  );
};
