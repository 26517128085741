import React, { FunctionComponent, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Column } from "react-table";
import { Container, FormGroup, Input, Label } from "reactstrap";
import { FoldoutForm } from "../../components/FoldoutForm/FoldoutForm";
import { FastlyTable } from "../../components/Table/FastlyTable";
import { useAddonService } from "../../services/useAddonService";
import { IAddonResponse } from "../../types/addon";
import { IRequestResponse } from "../../utils/api";

const costTypesColumns: Column<IAddonResponse>[] = [
  {
    Header: "Namn",
    accessor: "name",
  },
  {
    Header: "Konstnad",
    accessor: "base_rent",
  },
];

export interface IAddonListPanelProps {
  spaceId: string;
  addons: IAddonResponse[];
}

export const AddonListPanel: FunctionComponent<IAddonListPanelProps> = ({
  spaceId,
  addons,
}) => {
  const { register, handleSubmit, reset } = useForm();
  const { fetchAddonTypes: fetchCostTypes, addCostToSpace } = useAddonService();
  const [isLoaded, setIsLoaded] = useState(false);
  const [costs, setCosts] = useState([] as IAddonResponse[]);
  const [costTypes, setCostTypes] = useState({
    loaded: false,
    data: [],
  } as IRequestResponse<IAddonResponse[]>);

  useEffect(() => {
    (async () => {
      setCostTypes(await fetchCostTypes());

      setIsLoaded(true);
    })();
  }, [fetchCostTypes]);

  useEffect(() => {
    if (addons) {
      setCosts(addons);
    }
  }, [addons]);

  const addCost = async (data: any) => {
    const { data: addedCost } = await addCostToSpace(spaceId, data.costId);

    setCosts([...costs, addedCost]);
    reset();
  };

  return (
    <>
      <FoldoutForm
        className="mt-5"
        title="Tillägg"
        buttonText="Lägg till"
        onSubmit={handleSubmit(addCost)}
      >
        <FormGroup>
          <Label for="costType">Tillägg</Label>
          <Input
            type="select"
            name="costId"
            id="costType"
            innerRef={register({ required: true })}
          >
            {costTypes.data.map(({ id, name }) => (
              <option key={id} value={id}>
                {name}
              </option>
            ))}
          </Input>
        </FormGroup>
      </FoldoutForm>
      <Container>
        <FastlyTable<IAddonResponse>
          loaded={isLoaded}
          columns={costTypesColumns}
          data={costs}
          onTableRowClick={addCost}
        />
      </Container>
    </>
  );
};
