import React, { FunctionComponent, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { Col, FormGroup, Label, Row } from "reactstrap";
import { LoaderInput } from "../../components/LoaderInput/LoaderInput";
import { ResourceForm } from "../../components/ResourceForm/ResourceForm";
import { usePropertyService } from "../../services/usePropertyService";
import { IDetailedPropertyResponse } from "../../types/property";
import { IRequestResponse } from "../../utils/api";
import { AddonListPanel } from "../Panels/AddonListPanel";
import { BuildingListPanel } from "../Panels/BuildingListPanel";
import { ParkingListPanel } from "../Panels/ParkingListPanel";

interface PropertyParams {
  propertyId: string;
}

export const Property: FunctionComponent<RouteComponentProps<
  PropertyParams
>> = ({ match: { params } }) => {
  const { register, handleSubmit } = useForm();
  const history = useHistory();
  const {
    fetchDetailedProperty,
    updateProperty,
    deleteProperty,
  } = usePropertyService();
  const [property, setProperty] = useState({
    loaded: false,
    data: {},
  } as IRequestResponse<IDetailedPropertyResponse>);

  useEffect(() => {
    (async () => {
      setProperty(await fetchDetailedProperty(params.propertyId));
    })();
  }, [fetchDetailedProperty, params.propertyId]);

  const onSubmit = (data: any) => {
    updateProperty(params.propertyId, data);
  };

  const onDelete = async () => {
    const response = await deleteProperty(params.propertyId)

    if (response.success) {
      history.push('/properties');
    }
  };

  return (
    <>
      <ResourceForm
        title={property.data.title}
        onSubmit={handleSubmit(onSubmit)}
        onDelete={onDelete}
      >
        <Row>
          <Col md={3}>
            <FormGroup>
              <Label for="propertyTitle">Beteckning</Label>
              <LoaderInput
                loaded={property.loaded}
                defaultValue={property.data.title}
                type="text"
                name="title"
                id="propertyTitle"
                innerRef={register}
              />
            </FormGroup>
          </Col>
          <Col md={3}>
            <FormGroup>
              <Label for="propertyCity">Ort</Label>
              <LoaderInput
                loaded={property.loaded}
                defaultValue={property.data.city}
                type="text"
                name="city"
                id="propertyCity"
                innerRef={register}
              />
            </FormGroup>
          </Col>
          <Col md={3}>
            <FormGroup>
              <Label for="propertyArea">Område</Label>
              <LoaderInput
                loaded={property.loaded}
                defaultValue={property.data.area}
                type="text"
                name="area"
                id="propertyArea"
                innerRef={register}
              />
            </FormGroup>
          </Col>
          <Col md={3}>
            <FormGroup>
              <Label for="propertyBg">Bankgiro</Label>
              <LoaderInput
                loaded={property.loaded}
                defaultValue={property.data.bg}
                type="text"
                name="bg"
                id="propertyBg"
                innerRef={register}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            <FormGroup>
              <Label>Kvadratmeter</Label>
              <LoaderInput
                loaded={property.loaded}
                defaultValue={`${property.data.square_meter} kvm`}
                type="text"
                plaintext
                disabled
              />
            </FormGroup>
          </Col>
          <Col md={3}>
            <FormGroup>
              <Label>Total hyra</Label>
              <LoaderInput
                loaded={property.loaded}
                defaultValue={`${property.data.total_rent} sek`}
                type="text"
                plaintext
                disabled
              />
            </FormGroup>
          </Col>
          <Col md={3}>
            <FormGroup>
              <Label>
                Hyra / m<sup>2</sup>
              </Label>
              <LoaderInput
                loaded={property.loaded}
                defaultValue={`${Math.round(
                  (property.data.total_rent || 0) /
                    (property.data.square_meter || 1)
                )} sek`}
                type="text"
                plaintext
                disabled
              />
            </FormGroup>
          </Col>
        </Row>
      </ResourceForm>
      <BuildingListPanel propertyId={params.propertyId} />
      <ParkingListPanel propertyId={params.propertyId} />
      <AddonListPanel
        spaceId={params.propertyId}
        addons={property.data.costs}
      />
    </>
  );
};
