import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Column } from "react-table";
import { Container, Badge } from "reactstrap";
import applicationIcon from "../../assets/images/icons/speculant.svg";
import { FastlyTable } from "../../components/Table/FastlyTable";
import { useApplicationService } from "../../services/useApplicationService";
import { INotificationResponse } from "../../types/application";
import { IRequestResponse } from "../../utils/api";
import { toPrettyDate } from "../../utils/fields";

const notificationColumns: Column<INotificationResponse>[] = [
  {
    Header: "Lägenhet",
    accessor: (row) => row.apartment.slug,
  },
  {
    Header: "Datum",
    accessor: ({ created }) => toPrettyDate(created),
  },
  {
    Header: "Status",
    accessor: "active",
    Cell: ({ row }) =>
      row.values.active ? <Badge color="success">Aktiv</Badge> : <Badge color="danger">Inaktiv</Badge>,
  },
];

export const NotificationListPanel = () => {
  const history = useHistory();
  const { fetchNotifications } = useApplicationService();
  const [notifications, setNotifications] = useState({
    loaded: false,
    data: [],
  } as IRequestResponse<INotificationResponse[]>);

  useEffect(() => {
    (async () => {
      setNotifications(await fetchNotifications());
    })();
  }, [fetchNotifications]);

  const onTableRowClick = (data: any) => {
    history.push(`/notifications/${data.id}`);
  };

  return (
    <Container>
      <h2 className="mb-4">Notifieringar</h2>
      <FastlyTable<INotificationResponse>
        loaded={notifications.loaded}
        columns={notificationColumns}
        data={notifications.data}
        onTableRowClick={onTableRowClick}
        icon={applicationIcon}
      />
    </Container>
  );
};
