import React, { PropsWithChildren } from "react";
import { Input, InputProps } from "reactstrap";
import "./LoaderInput.scss";

export interface LoaderInputProps extends PropsWithChildren<InputProps> {
  loaded: boolean;
}

export const LoaderInput: React.FC<PropsWithChildren<InputProps>> = ({
  loaded,
  children,
  ...props
}) =>
  loaded ? (
    <Input {...props}>{children}</Input>
  ) : (
    <Input className={"animated-background"} />
  );
