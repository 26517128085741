import React from "react";
import { IUser } from "../types/auth";

export interface IApiContext {
  loaded: boolean;
  client: string | undefined;
  user: IUser | undefined;
}

export const initialState = {
  loaded: false,
  client: undefined,
  user: undefined
};

export const ApiContext = React.createContext({} as IApiContext);
