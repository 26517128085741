import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { Column } from "react-table";
import {
  Badge,
  Col,
  Container,
  DropdownItem,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { ActionButton } from "../../components/ActionButton/ActionButton";
import { FoldoutForm } from "../../components/FoldoutForm/FoldoutForm";
import { FastlyTable } from "../../components/Table/FastlyTable";
import { residentRentalStatusColor } from "../../config/colors";
import { residentRentalStatusText } from "../../config/strings";
import { useRentalService } from "../../services/useRentalService";
import { IDetailedRentalResponse, IRentalResponse } from "../../types/rental";
import { ResidentRentalStatus } from "../../types/resident";
import { IRequestResponse } from "../../utils/api";

export interface IRentalListObject {
  id?: string;
  name: string;
  rent: number;
  type: string;
  valid_from?: string;
  valid_to?: string;
  status: ResidentRentalStatus;
}

export interface IRentalListPanelProps {
  loaded: boolean;
  residentId: string;
  rentals: IDetailedRentalResponse[];
}

export const RentalListPanel: React.FC<IRentalListPanelProps> = ({
  loaded,
  residentId,
  rentals = [],
}) => {
  const {
    fetchAvailableRentals,
    addResidentToRental,
    updateResidentRentalRelation,
    removeRentalFromResident,
  } = useRentalService();
  const history = useHistory();
  const { register, handleSubmit } = useForm();
  const [currentRentals, setCurrentRentals] = useState(rentals);
  const [addedRentals, setAddedRentals] = useState<IDetailedRentalResponse[]>(
    []
  );
  const [availableRentals, setAvailableRentals] = useState<
    IRequestResponse<IRentalResponse[]>
  >({ loaded: false, data: [] });

  useEffect(() => {
    setCurrentRentals(rentals);
  }, [rentals]);

  const rentalsAndCosts = useMemo(
    () =>
      [...addedRentals, ...currentRentals].reduce(
        (agg: IRentalListObject[], curr): IRentalListObject[] => [
          ...agg,
          {
            id: curr.id,
            name: curr.slug,
            rent: curr.monthly_rent,
            type: curr.type,
            valid_from: curr.valid_from,
            valid_to: curr.valid_to,
            status: curr.status,
          },
          ...curr.costs.map((cost) => ({
            name: cost.name,
            rent: cost.base_rent,
            type: "cost",
            status: curr.status,
          })),
        ],
        []
      ),
    [addedRentals, currentRentals]
  );

  const rentalColumns: Column<IRentalListObject>[] = [
    {
      accessor: "status",
      Cell: ({ value }) => (
        <Badge color={residentRentalStatusColor[value]}>
          {residentRentalStatusText[value]}
        </Badge>
      ),
    },
    {
      Header: "Objekt",
      accessor: "name",
    },
    {
      Header: "Hyra",
      accessor: "rent",
    },
    {
      Header: "Fr.o.m.",
      accessor: "valid_from",
      Cell: ({ row }) =>
        row.original.id ? (
          <Input
            onClick={(e) => e.stopPropagation()}
            onChange={(e) =>
              row.original.id &&
              onChange(row.original.id, { valid_from: e.target.value })
            }
            bsSize="sm"
            defaultValue={row.original.valid_from}
            type="date"
          />
        ) : (
          false
        ),
    },
    {
      Header: "T.o.m.",
      accessor: "valid_to",
      Cell: ({ row }) =>
        row.original.id ? (
          <Input
            onClick={(e) => e.stopPropagation()}
            onChange={(e) =>
              row.original.id &&
              onChange(row.original.id, { valid_to: e.target.value })
            }
            bsSize="sm"
            defaultValue={row.original.valid_to}
            type="date"
          />
        ) : (
          false
        ),
    },
    {
      accessor: "id",
      Cell: ({ value }) =>
        value ? (
          <ActionButton>
            <DropdownItem onClick={() => onRemove(value)}>Ta bort</DropdownItem>
          </ActionButton>
        ) : (
          false
        ),
    },
  ];

  const onExpand = async () => {
    setAvailableRentals(await fetchAvailableRentals());
  };

  const onChange = (
    rentalId: string,
    data: { valid_from?: string; valid_to?: string }
  ) => {
    updateResidentRentalRelation(residentId, rentalId, data);
  };

  const onRemove = async (rentalId: string) => {
    const { success } = await removeRentalFromResident(residentId, rentalId);

    if (success) {
      setCurrentRentals(rentals.filter((rental) => rental.id !== rentalId));
    }
  };

  const onSubmit = async (data: any) => {
    const addedRental = await addResidentToRental(
      residentId,
      data.rental_id,
      data
    );

    if (addedRental.success) {
      setAddedRentals([addedRental.data, ...addedRentals]);
    }
  };

  return (
    <>
      <FoldoutForm
        title="Hyresobjekt"
        buttonText="Lägg till"
        onExpand={onExpand}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="residentRentalId">Hyresobjekt</Label>
              <Input
                type="select"
                name="rental_id"
                id="residentRentalId"
                innerRef={register({ required: true })}
              >
                {availableRentals.data.map(({ id, slug }) => (
                  <option key={id} value={id}>
                    {slug}
                  </option>
                ))}
              </Input>
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="residentMoveIn">Inflyttningsdatum</Label>
              <Input
                type="date"
                name="valid_from"
                id="residentMoveIn"
                innerRef={register({ required: true })}
              />
            </FormGroup>
          </Col>
        </Row>
      </FoldoutForm>
      <Container>
        <FastlyTable<IRentalListObject>
          loaded={loaded}
          columns={rentalColumns}
          data={rentalsAndCosts}
          onTableRowClick={(data) =>
            data.type === "apartment" && history.push(`/apartments/${data.id}`)
          }
        />
      </Container>
    </>
  );
};
