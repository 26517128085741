import { useCallback, useContext } from "react";
import { ApiContext } from "../context/api";
import { IPersonResponse } from "../types/resident";
import { ApiRequestMethod, clientApiRequestService } from "../utils/api";

export const usePersonService = () => {
  const { client } = useContext(ApiContext);

  const fetchPerson = (personId: string) =>
    clientApiRequestService<IPersonResponse>({
      client,
      method: ApiRequestMethod.Get,
      path: `/persons/${personId}`,
    });

  const updatePerson = (personId: string, data: any) =>
    clientApiRequestService<IPersonResponse>({
      client,
      method: ApiRequestMethod.Put,
      path: `/persons/${personId}`,
      data,
    });

  return {
    fetchPerson: useCallback(fetchPerson, []),
    updatePerson: useCallback(updatePerson, []),
  };
};
