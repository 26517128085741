import QRCode from "qrcode.react";
import React, { useEffect, useState } from "react";
import { useApartmentService } from "../../services/useApartmentService";
import { useSettingService } from "../../services/useSettingService";
import { toPrettyUrl } from "../../utils/fields";
import "./Codes.scss";

export const Codes = () => {
  const { fetchApartments } = useApartmentService();
  const { fetchSettings } = useSettingService();
  const [qrCodes, setQrCodes] =
    useState<{ title: string; url: string; website: string }[]>();

  useEffect(() => {
    (async () => {
      const { data: apartments } = await fetchApartments();
      const { data: settings } = await fetchSettings();

      setQrCodes(
        apartments
          .sort((a, b) => (a.slug < b.slug ? -1 : 0))
          .map((apartment) => ({
            title: apartment.slug,
            website: settings.website,
            url: apartment.login_url,
          }))
      );
    })();
  }, [fetchApartments, fetchSettings]);

  return qrCodes ? (
    <ul className="Codes">
      {qrCodes.map((code) => (
        <li key={code.url} className="Codes__code text-center">
          <div className="Codes__border p-4">
            <QRCode className="mb-3" value={code.url} renderAs="svg" />
            <h4 className="m-0">{toPrettyUrl(code.website)}</h4>
          </div>
          <h4 className="small mb-4 mt-4">{code.title}</h4>
        </li>
      ))}
    </ul>
  ) : (
    false
  );
};
