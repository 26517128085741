import { removeEmptyFields } from "./forms";

export enum ApiRequestMethod {
  Get = "GET",
  Post = "POST",
  Put = "PUT",
  Patch = "PATCH",
  Delete = "DELETE",
}

export interface IRequestParams {
  method: ApiRequestMethod;
  path: string;
  data?: any;
}

export interface IClientRequestParams extends IRequestParams {
  client: string | undefined;
}

export interface IRequestResponse<T> {
  loaded: boolean;
  success?: boolean;
  data: T;
}

export const apiHost = process.env.REACT_APP_API_URL || "http://localhost:9000";

const parseResponse = async (response: Response) => {
  try {
    return await response.json();
  } catch {
    return undefined;
  }
};

export const apiRequestService = async <T>({
  method,
  path,
  data,
}: IRequestParams) => {
  const response = await fetch(`${apiHost}/api${path}`, {
    method,
    credentials: "include",
    headers: {
      "Content-Type": " application/json",
    },
    body: JSON.stringify(data),
  });

  const json = await parseResponse(response);

  return {
    loaded: true,
    success: response.ok,
    data: json,
  };
};

export const clientApiRequestService = async <T>({
  client,
  method,
  path,
  data,
}: IClientRequestParams): Promise<IRequestResponse<T>> => {
  return apiRequestService({
    method,
    path: `/clients/${client}${path}`,
    ...(data && { data: removeEmptyFields(data) }),
  });
};
