import { useCallback, useContext } from "react";
import { ApiContext } from "../context/api";
import { IAreaResponse } from "../types/area";
import { IRoomTemplateResponse, ISettingsResponse } from "../types/settings";
import {
  ApiRequestMethod,
  apiRequestService,
  clientApiRequestService,
} from "../utils/api";

export const useSettingService = () => {
  const { client } = useContext(ApiContext);

  const fetchSettings = () =>
    clientApiRequestService<ISettingsResponse>({
      client,
      method: ApiRequestMethod.Get,
      path: "",
    });

  const updateSettings = (data: any) =>
    clientApiRequestService<ISettingsResponse>({
      client,
      method: ApiRequestMethod.Put,
      path: "",
      data,
    });

  const fetchAreas = () =>
    clientApiRequestService<IAreaResponse[]>({
      client,
      method: ApiRequestMethod.Get,
      path: `/areas`,
    });

  const fetchRoomTemplates = () =>
    apiRequestService<IRoomTemplateResponse[]>({
      method: ApiRequestMethod.Get,
      path: `/room_templates`,
    });

  return {
    fetchRoomTemplates: useCallback(fetchRoomTemplates, []),
    fetchAreas: useCallback(fetchAreas, []),
    fetchSettings: useCallback(fetchSettings, []),
    updateSettings: useCallback(updateSettings, []),
  };
};
