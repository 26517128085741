import React from "react";
import { Button, Container } from "reactstrap";
import { range } from "../../utils/arrays";
import { clamp } from "../../utils/math";
import "./Paginator.css";

interface IPaginatorProps {
  offset: number | undefined;
  totalCount: number;
  setOffset: (x: number) => void;
  pageSize: number;
  loaded: boolean;
}

export const Paginator = ({
  offset = 0,
  totalCount,
  setOffset,
  pageSize,
  loaded,
}: IPaginatorProps) => {
  const totalPages = Math.ceil(totalCount / pageSize);
  const currentPage = offset / pageSize;

  const start =
    clamp(currentPage - 2, 0, totalPages) -
    clamp(3 - totalPages + currentPage, 0, 3);
  const end =
    clamp(currentPage + 3, 0, totalPages) + clamp(2 - currentPage, 0, 2);

  return !loaded || totalPages === 1 ? null : (
    <Container className="Paginator__controls">
      <Button
        className="Paginator__controls_link"
        size="lg"
        onClick={() => setOffset(0)}
      >
        {"<<"}
      </Button>

      <Button
        className="Paginator__controls_link"
        size="lg"
        onClick={() => setOffset(offset - pageSize)}
      >
        {"<"}
      </Button>

      {range(start, end).map((index) => (
        <Button
          className="Paginator__controls_link"
          size="lg"
          color={index === currentPage ? "red" : "black"}
          onClick={() => setOffset(20 * index)}
          key={index}
        >
          {index + 1}
        </Button>
      ))}

      <Button
        className="Paginator__controls_link"
        size="lg"
        onClick={() => setOffset(offset + pageSize)}
      >
        {">"}
      </Button>

      <Button
        className="Paginator__controls_link"
        size="lg"
        onClick={() => setOffset(totalCount - (totalCount % pageSize))}
      >
        {">>"}
      </Button>
    </Container>
  );
};
