import { useCallback, useContext } from "react";
import { ApiContext } from "../context/api";
import {
  IDetailedReportResponse,
  IReportResponse,
  ReportStatus,
} from "../types/report";
import { ApiRequestMethod, clientApiRequestService } from "../utils/api";

export const useReportService = () => {
  const { client } = useContext(ApiContext);

  const fetchReports = () =>
    clientApiRequestService<IReportResponse[]>({
      client,
      method: ApiRequestMethod.Get,
      path: "/reports",
    });

  const fetchDetailedReport = (reportId: string) =>
    clientApiRequestService<IDetailedReportResponse>({
      client,
      method: ApiRequestMethod.Get,
      path: `/reports/${reportId}`,
    });

  const patchReportStatus = (reportId: string, status: ReportStatus) =>
    clientApiRequestService<IDetailedReportResponse>({
      client,
      method: ApiRequestMethod.Patch,
      path: `/reports/${reportId}`,
      data: {
        status,
      },
    });

  return {
    fetchReports: useCallback(fetchReports, []),
    fetchDetailedReport: useCallback(fetchDetailedReport, []),
    patchReportStatus: useCallback(patchReportStatus, []),
  };
};
