import React, { useState } from "react";
import { CSVDownload } from "react-csv";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { Button, Col, Container, FormGroup, Input, Label } from "reactstrap";
import { useRentalService } from "../../services/useRentalService";
import { readFileAsJson } from "../../utils/files";

export const Export = () => {
  const { exportRentals, importRentals } = useRentalService();
  const [rentalsExport, setRentalsExport] = useState<any>();
  const { register, getValues } = useForm();

  const downloadApartmentsExport = async () => {
    const { success, data } = await exportRentals();

    if (success) {
      setRentalsExport(data);
    }
  };

  const handleImport = async () => {
    const json = await readFileAsJson(getValues("file")[0]);

    importRentals(json);
  };

  return (
    <Container>
      <h2 className="mb-5">Export / Import</h2>
      <FormGroup row>
        <Label sm={4}>Exportera hyresobjekt</Label>
        <Col sm={8}>
          <Button className="float-sm-right" onClick={downloadApartmentsExport}>
            Exportera
          </Button>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={4}>Importera hyresobjekt</Label>
        <Col sm={4}>
          <Input
            type="file"
            name="file"
            label="Välj en fil"
            innerRef={register}
          />
        </Col>
        <Col sm={4}>
          <Button className="float-sm-right" onClick={handleImport}>
            Importera
          </Button>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={4}>Exportera QR-koder</Label>
        <Col sm={8}>
          <Link className="float-sm-right" to="/export/codes">
            <Button>Exportera</Button>
          </Link>
        </Col>
      </FormGroup>
      {rentalsExport && <CSVDownload data={rentalsExport} />}
    </Container>
  );
};
