import React, { FunctionComponent, useContext } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import "./App.scss";
import { Footer } from "./components/Footer/Footer";
import { Header } from "./components/Header/Header";
import { Menu } from "./components/Menu/Menu";
import { Addons } from "./containers/Addons/Addons";
import { Apartment } from "./containers/Apartments/Apartment";
import { Apartments } from "./containers/Apartments/Apartments";
import { Application } from "./containers/Applications/Application";
import { Applications } from "./containers/Applications/Applications";
import { ApiContextProvider } from "./containers/AuthProvider/ApiProvider";
import { Building } from "./containers/Buildings/Building";
import { Buildings } from "./containers/Buildings/Buildings";
import { Codes } from "./containers/Export/Codes";
import { Export } from "./containers/Export/Export";
import { Login } from "./containers/Login/Login";
import { Notification } from "./containers/Notifications/Notification";
import { Overview } from "./containers/Overview/Overview";
import { Person } from "./containers/Persons/Person";
import { Properties } from "./containers/Properties/Properties";
import { Property } from "./containers/Properties/Property";
import { Report } from "./containers/Reports/Report";
import { Reports } from "./containers/Reports/Reports";
import { Resident } from "./containers/Residents/Resident";
import { Residents } from "./containers/Residents/Residents";
import { Settings } from "./containers/Settings/Settings";
import { ApiContext } from "./context/api";

const PrivateRoute: FunctionComponent<any> = ({
  component: Component,
  ...rest
}) => {
  const { loaded, user } = useContext(ApiContext);

  return (
    <Route
      {...rest}
      render={(props) =>
        loaded && user ? (
          <>
            <Header />
            <Container className="container-xl">
              <Row>
                <Col lg="3">
                  <Menu />
                </Col>
                <Col lg="9">
                  <Component {...props} />
                </Col>
              </Row>
            </Container>
          </>
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};

const PrintRoute: FunctionComponent<any> = ({
  component: Component,
  ...rest
}) => {
  const { loaded, user } = useContext(ApiContext);

  return (
    <Route
      {...rest}
      render={(props) =>
        loaded && user ? <Component {...props} /> : <Redirect to="/login" />
      }
    />
  );
};

const RestrictedRoute: FunctionComponent<any> = ({
  component: Component,
  restricted,
  ...rest
}) => {
  const { loaded, user } = useContext(ApiContext);

  return (
    <Route
      {...rest}
      render={(props) =>
        loaded && user && restricted ? (
          <Redirect to="/overview" />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

const App = () => {
  const { loaded } = useContext(ApiContext);

  return (
    <BrowserRouter>
      <div className="App">
        <Switch>
          {loaded && (
            <>
              <RestrictedRoute
                exact
                restricted
                path="/login"
                component={Login}
              />
              <PrivateRoute path="/" exact component={Overview} />
              <PrivateRoute path="/overview" exact component={Overview} />
              <PrivateRoute path="/properties" exact component={Properties} />
              <PrivateRoute path="/buildings" exact component={Buildings} />
              <PrivateRoute path="/apartments" exact component={Apartments} />
              <PrivateRoute path="/residents" exact component={Residents} />
              <PrivateRoute path="/addons" exact component={Addons} />
              <PrivateRoute
                path="/applications"
                exact
                component={Applications}
              />
              <PrivateRoute path="/reports" exact component={Reports} />
              <PrivateRoute path="/settings" exact component={Settings} />
              <PrivateRoute path="/export" exact component={Export} />
              <PrivateRoute
                path="/properties/:propertyId"
                exact
                component={Property}
              />
              <PrivateRoute
                path="/buildings/:buildingId"
                exact
                component={Building}
              />
              <PrivateRoute
                path="/apartments/:apartmentId"
                exact
                component={Apartment}
              />
              <PrivateRoute
                path="/residents/:residentId"
                exact
                component={Resident}
              />
              <PrivateRoute
                path="/applications/:applicationId"
                exact
                component={Application}
              />
              <PrivateRoute
                path="/notifications/:notificationId"
                exact
                component={Notification}
              />
              <PrivateRoute
                path="/reports/:reportId"
                exact
                component={Report}
              />
              <PrivateRoute
                path="/persons/:personId"
                exact
                component={Person}
              />
              <PrintRoute path="/export/codes" exact component={Codes} />
            </>
          )}
        </Switch>
      </div>
      <Footer />
    </BrowserRouter>
  );
};

export default () => (
  <ApiContextProvider>
    <App />
  </ApiContextProvider>
);
