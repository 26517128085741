import React, { FunctionComponent, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { Col, FormGroup, Label, Row } from "reactstrap";
import { LoaderInput } from "../../components/LoaderInput/LoaderInput";
import { ResourceForm } from "../../components/ResourceForm/ResourceForm";
import { useBuildingService } from "../../services/useBuildingService";
import { IDetailedBuildingResponse } from "../../types/building";
import { IRequestResponse } from "../../utils/api";
import { AddonListPanel } from "../Panels/AddonListPanel";
import { ApartmentListPanel } from "../Panels/ApartmentListPanel";

interface BuildingParams {
  buildingId: string;
}

export const Building: FunctionComponent<RouteComponentProps<
  BuildingParams
>> = ({ match: { params } }) => {
  const { register, handleSubmit } = useForm();
  const history = useHistory();
  const {
    fetchDetailedBuilding,
    updateBuilding,
    deleteBuilding,
  } = useBuildingService();
  const [building, setBuilding] = useState({
    loaded: false,
    data: {},
  } as IRequestResponse<IDetailedBuildingResponse>);

  useEffect(() => {
    (async () => {
      setBuilding(await fetchDetailedBuilding(params.buildingId));
    })();
  }, [fetchDetailedBuilding, params.buildingId]);

  const onSubmit = async (data: any) => {
    const updateBuildingResponse = await updateBuilding(
      params.buildingId,
      data
    );

    if (updateBuildingResponse.success) {
      setBuilding(updateBuildingResponse);
    }
  };

  const onDelete = async () => {
    const response = await deleteBuilding(params.buildingId);

    if (response.success) {
      history.push("/buildings");
    }
  };

  return (
    <>
      <ResourceForm
        title={building.data.slug}
        onSubmit={handleSubmit(onSubmit)}
        onDelete={onDelete}
      >
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="buildingAddress">Adress</Label>
              <LoaderInput
                loaded={building.loaded}
                defaultValue={building.data.address}
                type="text"
                name="address"
                id="buildingAddress"
                innerRef={register}
              />
            </FormGroup>
          </Col>
        </Row>
      </ResourceForm>
      <ApartmentListPanel buildingId={params.buildingId} />
      <AddonListPanel
        spaceId={params.buildingId}
        addons={building.data.costs}
      />
    </>
  );
};
