import React, { FunctionComponent, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { RouteComponentProps } from "react-router-dom";
import { Alert, Col, FormGroup, Label, Row } from "reactstrap";
import { LoaderInput } from "../../components/LoaderInput/LoaderInput";
import { ResourceForm } from "../../components/ResourceForm/ResourceForm";
import { usePersonService } from "../../services/usePersonService";
import { IPersonResponse } from "../../types/resident";
import { IRequestResponse } from "../../utils/api";

interface PersonParams {
  personId: string;
}

export const Person: FunctionComponent<RouteComponentProps<PersonParams>> = ({
  match: { params },
}) => {
  const { register, handleSubmit } = useForm();
  const { fetchPerson, updatePerson } = usePersonService();
  const [person, setPerson] = useState({
    loaded: false,
    data: {},
  } as IRequestResponse<IPersonResponse>);

  useEffect(() => {
    (async () => {
      setPerson(await fetchPerson(params.personId));
    })();
  }, [fetchPerson, params.personId]);

  const onSubmit = async (form: any) => {
    const response = await updatePerson(params.personId, form);

    setPerson({
      loaded: response.loaded,
      success: response.success,
      data: {
        ...person.data,
        ...response.data,
      },
    });
  };

  return (
    <>
      <ResourceForm
        title={person.data.fullname}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Alert
          color="danger"
          isOpen={person.loaded && !person.success}
          fade={false}
        >
          Kunde inte uppdatera person. Sannolikt finns redan mejladressen!
        </Alert>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="personFirstname">Förnamn</Label>
              <LoaderInput
                loaded={person.loaded}
                defaultValue={person.data.firstname}
                type="text"
                name="firstname"
                id="personFirstname"
                innerRef={register}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="personLastname">Efternamn</Label>
              <LoaderInput
                loaded={person.loaded}
                defaultValue={person.data.lastname}
                type="text"
                name="lastname"
                id="personLastname"
                innerRef={register}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="personSsn">Personnummer</Label>
              <LoaderInput
                loaded={person.loaded}
                defaultValue={person.data.ssn}
                type="text"
                name="ssn"
                id="personSsn"
                innerRef={register}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="personPhone">Telefon</Label>
              <LoaderInput
                loaded={person.loaded}
                defaultValue={person.data.phone}
                type="text"
                name="phone"
                id="personPhone"
                innerRef={register}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <FormGroup>
              <Label for="personEmail">E-post</Label>
              <LoaderInput
                loaded={person.loaded}
                defaultValue={person.data.email}
                type="email"
                name="email"
                id="personEmail"
                innerRef={register}
              />
            </FormGroup>
          </Col>
        </Row>
      </ResourceForm>
    </>
  );
};
