import { useCallback, useContext } from "react";
import { ApiContext } from "../context/api";
import {
  IApartmentResponse,
  IDetailedApartmentResponse,
} from "../types/apartment";
import { ApiRequestMethod, clientApiRequestService } from "../utils/api";

export const useApartmentService = () => {
  const { client } = useContext(ApiContext);

  const fetchApartments = () =>
    clientApiRequestService<IApartmentResponse[]>({
      client,
      method: ApiRequestMethod.Get,
      path: "/apartments",
    });

  const fetchDetailedApartment = (apartmentId: string) =>
    clientApiRequestService<IDetailedApartmentResponse>({
      client,
      method: ApiRequestMethod.Get,
      path: `/apartments/${apartmentId}`,
    });

  const fetchApartmentsForBuilding = (buildingId: string) =>
    clientApiRequestService<IApartmentResponse[]>({
      client,
      method: ApiRequestMethod.Get,
      path: `/buildings/${buildingId}/apartments`,
    });

  const createApartmentForBuilding = (buildingId: string, data: any) =>
    clientApiRequestService<IApartmentResponse>({
      client,
      method: ApiRequestMethod.Post,
      path: `/buildings/${buildingId}/apartments`,
      data,
    });

  const updateApartment = (apartmentId: string, data: any) =>
    clientApiRequestService<IDetailedApartmentResponse>({
      client,
      method: ApiRequestMethod.Put,
      path: `/apartments/${apartmentId}`,
      data,
    });

  const deleteApartment = (apartmentId: string) =>
    clientApiRequestService({
      client,
      method: ApiRequestMethod.Delete,
      path: `/apartments/${apartmentId}`,
    });

  return {
    fetchApartments: useCallback(fetchApartments, []),
    fetchDetailedApartment: useCallback(fetchDetailedApartment, []),
    fetchApartmentsForBuilding: useCallback(fetchApartmentsForBuilding, []),
    createApartmentForBuilding: useCallback(createApartmentForBuilding, []),
    updateApartment: useCallback(updateApartment, []),
    deleteApartment: useCallback(deleteApartment, []),
  };
};
