import { useContext, useCallback } from "react";
import { ApiContext } from "../context/api";
import { IOverviewResponse, IRecentResponse } from "../types/overview";
import { ApiRequestMethod, clientApiRequestService } from "../utils/api";

export const useOverviewService = () => {
  const { client } = useContext(ApiContext);

  const fetchOverview = () =>
    clientApiRequestService<IOverviewResponse>({
      client,
      method: ApiRequestMethod.Get,
      path: "/overview",
    });

  const fetchRecent = () =>
    clientApiRequestService<IRecentResponse[]>({
      client,
      method: ApiRequestMethod.Get,
      path: "/overview/recent",
    });

  return {
    fetchOverview: useCallback(fetchOverview, []),
    fetchRecent: useCallback(fetchRecent, []),
  };
};
