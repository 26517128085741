import React, { useState } from "react";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import actionIcon from "../../assets/images/icons/edit_menu.svg";
import { Icon } from "../Icon/Icon";

export const ActionButton: React.FC = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Dropdown
      isOpen={isOpen}
      toggle={(e: React.MouseEvent) => {
        e.stopPropagation();
        setIsOpen(!isOpen);
      }}
    >
      <DropdownToggle className="p-0" color="none" outline size="sm">
        <Icon image={actionIcon} />
      </DropdownToggle>
      <DropdownMenu right>{children}</DropdownMenu>
    </Dropdown>
  );
};
