export enum ReportStatus {
  Open = "open",
  InProgress = "inprogress",
  Resolved = "resolved",
}

export interface IReportResponse {
  id: string;
  call: boolean;
  use_key: boolean;
  information: string;
  date: string;
  status: ReportStatus;
  rental: {
    id: string;
    slug: string;
  };
}

export interface IDetailedReportResponse extends IReportResponse {
  persons: {
    fullname: string;
    email: string;
    phone: string;
  }[];
}
