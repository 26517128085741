import React, { useEffect, useState } from "react";
import { Col, Container, FormGroup, Label, Row } from "reactstrap";
import Apartment from "../../assets/images/icons/apartment.svg";
import Building from "../../assets/images/icons/building.svg";
import Person from "../../assets/images/icons/person.svg";
import Property from "../../assets/images/icons/property.svg";
import Rent from "../../assets/images/icons/rent.svg";
import { Icon } from "../../components/Icon/Icon";
import { RecentFeed } from "../../components/RecentFeed/RecentFeed";
import { useOverviewService } from "../../services/useOverviewService";
import { IOverviewResponse, IRecentResponse } from "../../types/overview";
import { IRequestResponse } from "../../utils/api";

export const Overview = () => {
  const { fetchOverview, fetchRecent } = useOverviewService();
  const [overview, setOverview] = useState({} as IOverviewResponse);
  const [recentActivity, setRecentActivty] = useState<
    IRequestResponse<IRecentResponse[]>
  >({
    loaded: false,
    data: [],
  });

  useEffect(() => {
    (async () => {
      setOverview((await fetchOverview()).data);
      setRecentActivty((await fetchRecent()));
    })();
  }, [fetchOverview, fetchRecent]);

  return (
    <div>
      <Container className="mx-0">
        <h2 className="mb-5">Översikt</h2>
        <Row>
          <Col md={4}>
            <FormGroup className="p-0">
              <Icon image={Property} />
              <Label className="ml-2">Fastigheter</Label>
              <h1>{overview.properties}</h1>
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup className="p-0">
              <Icon image={Building} />
              <Label className="ml-2">Byggnader</Label>
              <h1>{overview.buildings}</h1>
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup className="p-0">
              <Icon image={Apartment} />
              <Label className="ml-2">Lägenheter</Label>
              <h1>{overview.apartments}</h1>
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup className="p-0">
              <Icon image={Person} />
              <Label className="ml-2">Hyresgäster</Label>
              <h1>{overview.residents}</h1>
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup className="p-0">
              <Icon image={Rent} />
              <Label className="ml-2">Total hyra</Label>
              <h1>{overview.total_rent}</h1>
            </FormGroup>
          </Col>
        </Row>
      </Container>
      <RecentFeed loaded={recentActivity.loaded} recent={recentActivity.data} />
    </div>
  );
};
