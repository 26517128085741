import React, { FunctionComponent } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { Column } from "react-table";
import {
  Col,
  Container,
  DropdownItem,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import personIcon from "../../assets/images/icons/person.svg";
import { ActionButton } from "../../components/ActionButton/ActionButton";
import { FoldoutForm } from "../../components/FoldoutForm/FoldoutForm";
import { FastlyTable } from "../../components/Table/FastlyTable";

export interface IPersonListPanelProps {
  loaded: boolean;
  persons: any[];
  onRemove?: (personId: string) => void;
  onSubmit?: (data: any) => void;
}

export const PersonListPanel: FunctionComponent<IPersonListPanelProps> = ({
  loaded,
  persons = [],
  onRemove,
  onSubmit,
}) => {
  const history = useHistory();
  const { register, handleSubmit } = useForm();

  const personColumns: Column[] = [
    {
      Header: "Namn",
      accessor: "fullname",
    },
    {
      Header: "Telefonnummer",
      accessor: "phone",
    },
    {
      Header: "E-post",
      accessor: "email",
    },
    {
      accessor: "id",
      Cell: ({ value }) =>
        value && onRemove ? (
          <ActionButton>
            <DropdownItem onClick={() => onRemove && onRemove(value)}>
              Ta bort
            </DropdownItem>
          </ActionButton>
        ) : (
          false
        ),
    },
  ];

  return (
    <>
      <FoldoutForm
        title="Personer"
        buttonText="Lägg till"
        onSubmit={onSubmit && handleSubmit(onSubmit)}
      >
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="personFirstname">Förnamn</Label>
              <Input
                type="text"
                name="firstname"
                id="personFirstname"
                innerRef={register({ required: true })}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="personLastname">Efternamn</Label>
              <Input
                type="text"
                name="lastname"
                id="personLastname"
                innerRef={register({ required: true })}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="personSsn">Personnummer</Label>
              <Input
                type="text"
                name="ssn"
                id="personSsn"
                innerRef={register()}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="personPhone">Telefonnummer</Label>
              <Input
                type="text"
                name="phone"
                id="personPhone"
                innerRef={register()}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <Label for="personEmail">E-post</Label>
              <Input
                type="email"
                name="email"
                id="personEmail"
                innerRef={register()}
              />
            </FormGroup>
          </Col>
        </Row>
      </FoldoutForm>
      <Container>
        <FastlyTable
          loaded={loaded}
          columns={personColumns}
          data={persons}
          icon={personIcon}
          onTableRowClick={({ id }) => history.push(`/persons/${id}`)}
        />
      </Container>
    </>
  );
};
