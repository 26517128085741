import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { useSettingService } from "../../services/useSettingService";
import { ISettingsResponse } from "../../types/settings";
import { IRequestResponse } from "../../utils/api";

export const Settings: React.FC = () => {
  const { register, handleSubmit } = useForm();
  const { fetchSettings, updateSettings } = useSettingService();
  const [settings, setSettings] = useState({
    loaded: false,
    data: {},
  } as IRequestResponse<ISettingsResponse>);

  useEffect(() => {
    (async () => {
      setSettings(await fetchSettings());
    })();
  }, [fetchSettings]);

  const onSubmit = async (data: any) => {
    await updateSettings(data);
  };

  return (
    <Container>
      <h2>Inställningar</h2>
      <Form
        className="mt-5"
        title="Inställningar"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Row>
          <Col md={12}>
            <FormGroup>
              <Label for="settingsClient">Kontonamn</Label>
              <Input
                defaultValue={settings.data.name}
                type="text"
                id="settingsClient"
                plaintext
                readOnly
                disabled
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <FormGroup>
              <Label for="clientEmail">E-post</Label>
              <Input
                defaultValue={settings.data.email}
                type="email"
                name="email"
                id="clientEmail"
                innerRef={register}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <FormGroup>
              <Label for="clientWebsite">Webbsida</Label>
              <Input
                defaultValue={settings.data.website}
                type="text"
                name="website"
                id="clientWebsite"
                innerRef={register}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <FormGroup>
              <Label for="fromEmail">Skicka e-post från</Label>
              <Input
                defaultValue={settings.data.from_email}
                type="text"
                name="from_email"
                id="fromEmail"
                innerRef={register}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <FormGroup>
              <Label for="clientAcceptUrl">
                URL för omdirigering vid acceptering av ledig lägenhet:
              </Label>
              <Input
                defaultValue={settings.data.accept_url}
                type="text"
                name="accept_url"
                id="clientAcceptUrl"
                innerRef={register}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <FormGroup>
              <Label for="clientLoginUrl">
                URL för omdirigering vid inloggning genom QR-kod
              </Label>
              <Input
                defaultValue={settings.data.login_url}
                type="text"
                name="login_url"
                id="clientLoginUrl"
                innerRef={register}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <FormGroup>
              <Label for="clientApiKey">API-nyckel</Label>
              <Input
                defaultValue={settings.data.api_key}
                type="text"
                id="clientApiKey"
                plaintext
              />
            </FormGroup>
          </Col>
        </Row>
        <Button color="red">Spara</Button>
      </Form>
    </Container>
  );
};
