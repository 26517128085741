import React, { useEffect, useMemo, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { Link } from "react-router-dom";
import { Column } from "react-table";
import {
  ButtonDropdown,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormGroup,
  Input,
  Label,
} from "reactstrap";
import applicationIcon from "../../assets/images/icons/speculant.svg";
import { FastlyTable } from "../../components/Table/FastlyTable";
import {
  applicationByApartmentSelector,
  IApplicationByApartment,
} from "../../selectors/applications";
import { useApplicationService } from "../../services/useApplicationService";
import { IApplicationByApartmentResponse } from "../../types/application";
import { IRequestResponse } from "../../utils/api";

export interface INotifyPanelProps {
  apartmentId: string;
}

interface IApplicationByApartmentExcl extends IApplicationByApartment {
  excluded: boolean;
}

export const NotifyPanel: React.FC<INotifyPanelProps> = ({ apartmentId }) => {
  const [isSent, setIsSent] = useState(false);
  const [deleteIsExpanded, setDeleteIsExpanded] = useState(false);
  const { createNotificationForApartment, fetchApplicationsByApartment } =
    useApplicationService();
  const { register, handleSubmit, control } = useForm({
    defaultValues: {
      limit: "20",
    },
  });

  const options = useWatch<{
    filter: string[] | undefined;
    limit: number;
  }>({ control, name: ["limit", "filter"] });

  const [excludedApplications, setExcludedApplications] = useState<string[]>(
    []
  );
  const [applications, setApplications] = useState({
    loaded: false,
    data: [],
  } as IRequestResponse<(IApplicationByApartmentResponse & { excluded?: boolean })[]>);

  useEffect(() => {
    (async () => {
      setApplications(await fetchApplicationsByApartment(apartmentId, options));
    })();
  }, [apartmentId, fetchApplicationsByApartment, options]);

  const memoizedApplications = useMemo(
    () =>
      applicationByApartmentSelector(
        applications.data
      ) as IApplicationByApartmentExcl[],
    [applications.data]
  );

  const onRowSelect = (row: IApplicationByApartmentExcl) => {
    setExcludedApplications((s) =>
      row.excluded ? s.filter((a) => a !== row.id) : [...s, row.id]
    );
  };

  const applicationColumns: Column<IApplicationByApartmentExcl>[] = [
    {
      Header: "Exkludera",
      accessor: "excluded",
      Cell: ({ value: checked, row }) => (
        <Input
          style={{ marginLeft: 0 }}
          type="checkbox"
          checked={checked}
          onChange={() => onRowSelect(row.original)}
        />
      ),
    },
    {
      Header: "Namn",
      accessor: (c) => <Link to={`/applications/${c.id}`}>{c.fullname}</Link>,
    },
    {
      Header: "Rökare",
      accessor: "smoker",
    },
    {
      Header: "Husdjur",
      accessor: "pets",
    },
    {
      Header: "Datum",
      accessor: "date",
    },
  ];

  const sendNotification = (data: any) => {
    if (!isSent) {
      createNotificationForApartment(apartmentId, {
        ...data,
        exclude: excludedApplications,
      });
    }

    setIsSent(true);
  };

  return (
    <Container className="mt-5">
      <h3>Notifiera</h3>
      <Form>
        <FormGroup>
          <Label for="notificationDescription">
            Antal person att skicka ut till
          </Label>
          <Input
            type="select"
            name="limit"
            id="notificationLimit"
            innerRef={register}
          >
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="40">40</option>
            <option value="80">80</option>
          </Input>
        </FormGroup>
        <FormGroup>
          <Label>Exkludera:</Label>
          <div>
            <FormGroup check inline>
              <Label check>
                <Input
                  name="filter"
                  value="pets"
                  type="checkbox"
                  innerRef={register}
                />{" "}
                Intressenter med husdjur
              </Label>
            </FormGroup>
            <FormGroup check inline>
              <Label check>
                <Input
                  name="filter"
                  value="smoker"
                  type="checkbox"
                  innerRef={register}
                />{" "}
                Rökare
              </Label>
            </FormGroup>
          </div>
        </FormGroup>
        <FormGroup>
          <Label for="notificationDescription">Information</Label>
          <Input
            type="textarea"
            name="description"
            id="notificationDescription"
            rows={5}
            innerRef={register}
          />
        </FormGroup>
        <FormGroup>
          <ButtonDropdown
            isOpen={deleteIsExpanded}
            toggle={() => setDeleteIsExpanded(!deleteIsExpanded)}
          >
            {isSent ? (
              <Label disabled className="text-success">
                Skickad!
              </Label>
            ) : (
              <DropdownToggle caret color="black" outline>
                Skicka
              </DropdownToggle>
            )}

            <DropdownMenu>
              <DropdownItem onClick={handleSubmit(sendNotification)}>
                Bekräfta
              </DropdownItem>
              <DropdownItem>Avbryt</DropdownItem>
            </DropdownMenu>
          </ButtonDropdown>
        </FormGroup>
      </Form>

      <h2 className="mb-4">
        Skickar till intressenter ({memoizedApplications.length} st)
      </h2>
      <FastlyTable<IApplicationByApartmentExcl>
        loaded={applications.loaded}
        columns={applicationColumns}
        data={memoizedApplications}
        filter={false}
        icon={applicationIcon}
      />
    </Container>
  );
};
