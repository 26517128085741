import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { Column } from "react-table";
import { Badge, Container } from "reactstrap";
import apartmentIcon from "../../assets/images/icons/apartment.svg";
import { FastlyTable } from "../../components/Table/FastlyTable";
import { useApartmentService } from "../../services/useApartmentService";
import { IRequestResponse } from "../../utils/api";

const apartmentsColumns: Column<any>[] = [
  {
    Header: "Lägenhet",
    accessor: "slug",
  },
  {
    Header: "Grundhyra",
    accessor: "base_rent",
  },
  {
    Header: "Area",
    accessor: "area",
  },
  {
    id: "unoccupied",
    accessor: "unoccupied",
    Cell: ({ row }) =>
      row.values.unoccupied && <Badge color="success">Ledig</Badge>,
  },
  {
    id: "order",
    accessor: "order",
  },
];

export const Apartments = () => {
  const history = useHistory();
  const { fetchApartments } = useApartmentService();
  const [apartments, setApartments] = useState({
    loaded: false,
    data: [],
  } as IRequestResponse<any[]>);

  const memoizedApartments = useMemo(
    () =>
      apartments.data.map((apartment) => ({
        ...apartment,
        order: apartment.unoccupied ? 0 : 1,
      })),
    [apartments]
  );

  useEffect(() => {
    (async () => {
      setApartments(await fetchApartments());
    })();
  }, [fetchApartments]);

  return (
    <div>
      <Container>
        <h2 className="mb-4">Lägenheter</h2>
        <FastlyTable<any>
          loaded={apartments.loaded}
          columns={apartmentsColumns}
          data={memoizedApartments}
          onTableRowClick={(data) => history.push(`/apartments/${data.id}`)}
          icon={apartmentIcon}
          initialState={{
            sortBy: [{ id: "order" }],
            hiddenColumns: ["order"],
          }}
        />
      </Container>
    </div>
  );
};
