import { useContext, useCallback } from "react";
import { ApiContext } from "../context/api";
import {
  IResidentResponse,
  IDetailedResidentResponse,
  IPersonResponse,
} from "../types/resident";
import { ApiRequestMethod, clientApiRequestService } from "../utils/api";

export const useResidentService = () => {
  const { client } = useContext(ApiContext);

  const fetchResidents = () =>
    clientApiRequestService<IResidentResponse[]>({
      client,
      method: ApiRequestMethod.Get,
      path: "/residents",
    });

  const fetchDetailedResident = (residentId: string) =>
    clientApiRequestService<IDetailedResidentResponse>({
      client,
      method: ApiRequestMethod.Get,
      path: `/residents/${residentId}`,
    });

  const updateResident = (residentId: string, data: any) =>
    clientApiRequestService<IDetailedResidentResponse>({
      client,
      method: ApiRequestMethod.Put,
      path: `/residents/${residentId}`,
      data,
    });

  const fetchResidentForRental = (rentalId: string) =>
    clientApiRequestService<IResidentResponse[]>({
      client,
      method: ApiRequestMethod.Get,
      path: `/rentals/${rentalId}/resident`,
    });

  const addPersonToResident = (residentId: string, data: any) =>
    clientApiRequestService<IPersonResponse>({
      client,
      method: ApiRequestMethod.Post,
      path: `/residents/${residentId}/persons`,
      data,
    });

  const deletePersonFromResident = (residentId: string, personId: string) =>
    clientApiRequestService<IPersonResponse>({
      client,
      method: ApiRequestMethod.Delete,
      path: `/residents/${residentId}/persons/${personId}`,
    });

  const createResident = (data: any) =>
    clientApiRequestService<IResidentResponse>({
      client,
      method: ApiRequestMethod.Post,
      path: `/residents`,
      data,
    });

  return {
    fetchResidents: useCallback(fetchResidents, []),
    fetchDetailedResident: useCallback(fetchDetailedResident, []),
    updateResident: useCallback(updateResident, []),
    fetchResidentForRental: useCallback(fetchResidentForRental, []),
    addPersonToResident: useCallback(addPersonToResident, []),
    deletePersonFromResident: useCallback(deletePersonFromResident, []),
    createResident: useCallback(createResident, []),
  };
};
