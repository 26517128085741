import React from "react";
import {
  Column,
  TableState,
  useAsyncDebounce,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { Alert, Button, DropdownItem, Input, Table } from "reactstrap";
import { ActionButton } from "../ActionButton/ActionButton";
import { Icon } from "../Icon/Icon";
import { Loader } from "../Loader/Loader";

interface TableProps<T extends object> {
  loaded: boolean;
  columns: Column<T>[];
  data: T[];
  icon?: string;
  filter?: boolean;
  initialState?: Partial<TableState>;
  onTableRowClick?: (data: any) => void;
  onDelete?: (data: any) => void;
}

export const FastlyTable = <T extends object>({
  loaded,
  columns,
  data,
  icon,
  filter = true,
  initialState,
  onTableRowClick,
  onDelete,
}: TableProps<T>) => {
  const {
    getTableProps,
    headerGroups,
    getTableBodyProps,
    page,
    prepareRow,
    setPageSize,
    canNextPage,
    setGlobalFilter,
  } = useTable(
    {
      data,
      //@ts-expect-error TODO: fix the type error
      columns,
      initialState,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const handleDelete = (e: React.MouseEvent, data: any) => {
    e.stopPropagation();

    onDelete && onDelete(data);
  };

  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return loaded ? (
    data.length > 0 ? (
      <>
        <div>
          {filter && (
            <Input
              placeholder="Sök"
              onChange={(e) => onChange(e.target.value)}
              type="text"
            />
          )}
          <Table
            {...getTableProps()}
            hover
            className="mb-5 table-striped table-borderless"
          >
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {icon && <th className="fit border-top-0" />}
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      className="border-top-0"
                    >
                      {column.render("Header")}
                    </th>
                  ))}
                  {onDelete && <th className="fit border-top-0" />}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row);

                return (
                  <React.Fragment key={row.getRowProps().key}>
                    <tr
                      role={row.getRowProps().role}
                      className={onTableRowClick && "clickable"}
                      onClick={
                        onTableRowClick && (() => onTableRowClick(row.original))
                      }
                    >
                      {icon && (
                        <th scope="row">
                          <Icon image={icon} />
                        </th>
                      )}
                      {row.cells.map((cell) => (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      ))}
                      {onDelete && (
                        <th scope="row">
                          <ActionButton>
                            <DropdownItem
                              onClick={(e) => {
                                handleDelete(e, row.original);
                              }}
                            >
                              Ta bort
                            </DropdownItem>
                          </ActionButton>
                        </th>
                      )}
                    </tr>
                  </React.Fragment>
                );
              })}
            </tbody>
          </Table>
        </div>
        {canNextPage && (
          <Button block color="black" onClick={() => setPageSize(data.length)}>
            Visa alla ({data.length})
          </Button>
        )}
      </>
    ) : (
      <Alert color="info">Det finns ingen information att visa</Alert>
    )
  ) : (
    <div className="mt-5">
      <Loader />
    </div>
  );
};
