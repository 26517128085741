import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { Column } from "react-table";
import { Col, Container, FormGroup, Input, Label, Row } from "reactstrap";
import addonIcon from "../../assets/images/icons/cost.svg";
import { FoldoutForm } from "../../components/FoldoutForm/FoldoutForm";
import { FastlyTable } from "../../components/Table/FastlyTable";
import { useAddonService } from "../../services/useAddonService";
import { IAddonResponse } from "../../types/addon";
import { IRequestResponse } from "../../utils/api";
import { onEnterKeyPress } from "../../utils/forms";

export const Addons = () => {
  const {
    fetchAddonTypes,
    updateAddonType,
    createAddonType,
  } = useAddonService();
  const { register, handleSubmit } = useForm();
  const [initialAddonTypes, setInitialAddonTypes] = useState({
    loaded: false,
    data: [],
  } as IRequestResponse<IAddonResponse[]>);
  const [createdAddonTypes, setCreatedAddonTypes] = useState([] as any[]);

  const addonTypes = useMemo(
    () => [...initialAddonTypes.data, ...createdAddonTypes],
    [createdAddonTypes, initialAddonTypes.data]
  );

  useEffect(() => {
    (async () => {
      setInitialAddonTypes(await fetchAddonTypes());
    })();
  }, [fetchAddonTypes]);

  const onSubmit = async (data: any) => {
    setCreatedAddonTypes([
      ...createdAddonTypes,
      (await createAddonType(data)).data,
    ]);
  };

  const addonsColumns: Column<IAddonResponse>[] = [
    {
      Header: "Namn",
      accessor: "name",
    },
    {
      Header: "Månadshyra",
      accessor: "base_rent",
      Cell: ({ row, value }) => (
        <Input
          bsSize="sm"
          type="text"
          name="base_rent"
          defaultValue={value}
          onKeyPress={onEnterKeyPress((e) => {
            updateAddonType(row.original.id, {
              base_rent: (e.target as HTMLInputElement).value,
            });
          })}
        />
      ),
    },
  ];

  return (
    <div>
      <FoldoutForm
        title="Tillägg"
        buttonText="Lägg till"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Row form>
          <Col md={8}>
            <FormGroup>
              <Label for="addonName">Namn</Label>
              <Input
                type="text"
                name="name"
                id="addonName"
                innerRef={register({ required: true })}
              />
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <Label for="propertyBaseRent">Månadshyra</Label>
              <Input
                type="text"
                name="base_rent"
                id="propertyBaseRent"
                innerRef={register({ required: true })}
              />
            </FormGroup>
          </Col>
        </Row>
      </FoldoutForm>
      <Container>
        <FastlyTable
          loaded={initialAddonTypes.loaded}
          columns={addonsColumns}
          data={addonTypes}
          icon={addonIcon}
        />
      </Container>
    </div>
  );
};
