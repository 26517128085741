import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { RouteComponentProps } from "react-router-dom";
import { Col, FormGroup, Label, Row } from "reactstrap";
import { LoaderInput } from "../../components/LoaderInput/LoaderInput";
import { ResourceForm } from "../../components/ResourceForm/ResourceForm";
import { useResidentService } from "../../services/useResidentService";
import { IDetailedResidentResponse } from "../../types/resident";
import { IRequestResponse } from "../../utils/api";
import { PersonListPanel } from "../Panels/PersonListPanel";
import { RentalListPanel } from "../Panels/RentalListPanel";

interface ResidentParams {
  residentId: string;
}

export const Resident: React.FC<RouteComponentProps<ResidentParams>> = ({
  match: { params },
}) => {
  const { register, handleSubmit } = useForm();
  const {
    fetchDetailedResident,
    updateResident,
    addPersonToResident,
    deletePersonFromResident,
  } = useResidentService();
  const [resident, setResident] = useState({
    loaded: false,
    data: {},
  } as IRequestResponse<IDetailedResidentResponse>);

  useEffect(() => {
    (async () => {
      setResident(await fetchDetailedResident(params.residentId));
    })();
  }, [fetchDetailedResident, params.residentId]);

  const addPerson = async (data: any) => {
    const { success, data: newPerson } = await addPersonToResident(
      params.residentId,
      data
    );

    if (success) {
      setResident({
        loaded: true,
        data: {
          ...resident.data,
          persons: [...resident.data.persons, newPerson],
        },
      });
    }
  };

  const deletePerson = async (personId: string) => {
    const { success } = await deletePersonFromResident(
      params.residentId,
      personId
    );

    if (success) {
      setResident({
        loaded: true,
        data: {
          ...resident.data,
          persons: resident.data.persons.filter(
            (person) => person.id !== personId
          ),
        },
      });
    }
  };

  const onSubmit = (data: any) => {
    updateResident(params.residentId, data);
  };

  return (
    <>
      <ResourceForm title="Hyreskontrakt" onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="residentPaymentReference">Betalningsreferens</Label>
              <LoaderInput
                loaded={resident.loaded}
                defaultValue={resident.data.payment_reference}
                type="text"
                name="payment_reference"
                id="residentPaymentReference"
                innerRef={register}
              />
            </FormGroup>
          </Col>
        </Row>
      </ResourceForm>
      <PersonListPanel
        loaded={resident.loaded}
        persons={resident.data.persons}
        onSubmit={addPerson}
        onRemove={deletePerson}
      />
      <RentalListPanel
        loaded={resident.loaded}
        residentId={params.residentId}
        rentals={resident.data.rentals}
      />
    </>
  );
};
