import { useContext, useCallback } from "react";
import { ApiContext } from "../context/api";
import { IParkingResponse } from "../types/parking";
import { IParkingTypeResponse } from "../types/settings";
import {
  ApiRequestMethod,
  apiRequestService,
  clientApiRequestService,
} from "../utils/api";

export const useParkingService = () => {
  const { client } = useContext(ApiContext);

  const fetchParkingsForProperty = (propertyId: string) =>
    clientApiRequestService<IParkingResponse[]>({
      client,
      method: ApiRequestMethod.Get,
      path: `/properties/${propertyId}/parkings`,
    });

  const createParkingForProperty = (propertyId: string, data: any) =>
    clientApiRequestService<IParkingResponse>({
      client,
      method: ApiRequestMethod.Post,
      path: `/properties/${propertyId}/parkings`,
      data,
    });

  const fetchParkingTypes = () =>
    apiRequestService<IParkingTypeResponse[]>({
      method: ApiRequestMethod.Get,
      path: `/parking_types`,
    });

  return {
    fetchParkingsForProperty: useCallback(fetchParkingsForProperty, []),
    createParkingForProperty: useCallback(createParkingForProperty, []),
    fetchParkingTypes: useCallback(fetchParkingTypes, []),
  };
};
