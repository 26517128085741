import { useCallback, useContext } from "react";
import { ApiContext } from "../context/api";
import {
  IApplicationByApartmentResponse,
  IApplicationResponse,
  IApplicationResponsePaginated,
  IDetailedNotificationResponse,
  INotificationResponse,
} from "../types/application";
import { ApiRequestMethod, clientApiRequestService } from "../utils/api";

export const useApplicationService = () => {
  const { client } = useContext(ApiContext);

  const fetchApplications = (opts?: { name?: string; offset?: number }) => {
    const query = [];
    if (opts?.name) {
      query.push(`name=${opts.name}`);
    }
    if (opts?.offset) {
      query.push(`offset=${opts.offset}`);
    }

    return clientApiRequestService<IApplicationResponsePaginated>({
      client,
      method: ApiRequestMethod.Get,
      path: `/applications${query.length ? `?${query.join("&")}` : ""}`,
    });
  };

  const fetchApplicationsByApartment = (apartmentId: string, data: any) =>
    clientApiRequestService<IApplicationByApartmentResponse[]>({
      client,
      method: ApiRequestMethod.Post,
      path: `/apartments/${apartmentId}/applications`,
      data,
    });

  const prioritizeApplication = (applicationId: string, date: string) =>
    clientApiRequestService<IApplicationResponse>({
      client,
      method: ApiRequestMethod.Put,
      path: `/applications/${applicationId}`,
      data: {
        date,
      },
    });

  const fetchDetailedApplications = (applicationId: string) =>
    clientApiRequestService<IApplicationResponse>({
      client,
      method: ApiRequestMethod.Get,
      path: `/applications/${applicationId}`,
    });

  const deleteApplication = (applicationId: string) =>
    clientApiRequestService({
      client,
      method: ApiRequestMethod.Delete,
      path: `/applications/${applicationId}`,
    });

  const fetchNotifications = () =>
    clientApiRequestService<INotificationResponse[]>({
      client,
      method: ApiRequestMethod.Get,
      path: "/notifications",
    });

  const closeNotification = (notificationId: string, data: any) =>
    clientApiRequestService({
      client,
      method: ApiRequestMethod.Post,
      path: `/notifications/${notificationId}/close`,
      data,
    });

  const fetchDetailedNotification = (notificationId: string) =>
    clientApiRequestService<IDetailedNotificationResponse>({
      client,
      method: ApiRequestMethod.Get,
      path: `/notifications/${notificationId}`,
    });

  const createNotificationForApartment = (apartmentId: string, data: any) =>
    clientApiRequestService<IDetailedNotificationResponse>({
      client,
      method: ApiRequestMethod.Post,
      path: `/apartments/${apartmentId}/notifications`,
      data,
    });

  return {
    fetchApplications: useCallback(fetchApplications, []),
    fetchDetailedApplications: useCallback(fetchDetailedApplications, []),
    deleteApplication: useCallback(deleteApplication, []),
    createNotificationForApartment: useCallback(
      createNotificationForApartment,
      []
    ),
    fetchNotifications: useCallback(fetchNotifications, []),
    fetchApplicationsByApartment: useCallback(fetchApplicationsByApartment, []),
    closeNotification: useCallback(closeNotification, []),
    fetchDetailedNotification: useCallback(fetchDetailedNotification, []),
    prioritizeApplication: useCallback(prioritizeApplication, []),
  };
};
