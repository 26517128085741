import React, { useEffect, useMemo, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Column } from "react-table";
import {
  Alert,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { ResourceForm } from "../../components/ResourceForm/ResourceForm";
import { FastlyTable } from "../../components/Table/FastlyTable";
import { useApplicationService } from "../../services/useApplicationService";
import { IApplicationResponse } from "../../types/application";
import { IRequestResponse } from "../../utils/api";
import { Loader } from "../../components/Loader/Loader";
import { IAreaResponse } from "../../types/area";
import { IRoomTemplateResponse } from "../../types/settings";

interface ApplicationParams {
  applicationId: string;
}

const areaColumns: Column<IAreaResponse>[] = [
  {
    Header: "Område",
    accessor: "name",
  },
];

const apartmentTypeColumns: Column<IRoomTemplateResponse>[] = [
  {
    Header: "Lägenhetstyp",
    accessor: "name",
  },
];

export const Application: React.FC<RouteComponentProps<ApplicationParams>> = ({
  match: { params },
}) => {
  const { fetchDetailedApplications } = useApplicationService();
  const [application, setApplication] = useState({
    loaded: false,
    data: {},
  } as IRequestResponse<IApplicationResponse>);

  const person = useMemo(
    () =>
      application.loaded
        ? {
            name: application.data.person.fullname,
            ssn: application.data.person.ssn,
            phone: application.data.person.phone,
            email: application.data.person.email,
          }
        : {},
    [application.data, application.loaded]
  );

  const areas = useMemo(
    () => (application.loaded ? application.data.areas : []),
    [application.data, application.loaded]
  );

  const apartmentTypes = useMemo(
    () => (application.loaded ? application.data.apartment_types : []),
    [application.data, application.loaded]
  );

  useEffect(() => {
    (async () => {
      setApplication(await fetchDetailedApplications(params.applicationId));
    })();
  }, [fetchDetailedApplications, params.applicationId]);

  return (
    <>
      <ResourceForm title="Intresseanmälan">
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="personName">Namn</Label>
              <Input
                defaultValue={person.name}
                type="text"
                name="name"
                id="personName"
                plaintext
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="personSsn">Personnummer</Label>
              <Input
                defaultValue={person.ssn}
                type="text"
                name="ssn"
                id="personSsn"
                plaintext
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="personPhone">Telfonnummer</Label>
              <Input
                defaultValue={person.phone}
                type="text"
                name="phone"
                id="personPhone"
                plaintext
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="personEmail">E-post</Label>
              <Input
                defaultValue={person.email}
                type="text"
                name="email"
                id="personEmail"
                plaintext
              />
            </FormGroup>
          </Col>
        </Row>
      </ResourceForm>
      <Container>
        <h2>Områden</h2>
        <FastlyTable<IAreaResponse>
          loaded={application.loaded}
          columns={areaColumns}
          data={areas}
          onTableRowClick={() => {}}
        />
        <h2>Lägenhetstyper</h2>
        <FastlyTable<IRoomTemplateResponse>
          loaded={application.loaded}
          columns={apartmentTypeColumns}
          data={apartmentTypes}
          onTableRowClick={() => {}}
        />
        <h2>Information</h2>
        {application.loaded ? (
          application.data.information ? (
            <p>{application.data.information}</p>
          ) : (
            <Alert color="info">Det finns ingen information att visa</Alert>
          )
        ) : (
          <Loader />
        )}
      </Container>
    </>
  );
};
