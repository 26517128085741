import { ReportStatus } from "../types/report";
import { ResidentRentalStatus } from "../types/resident";

export const reportStatusText = {
  [ReportStatus.Open]: "Ohanterad",
  [ReportStatus.InProgress]: "Pågående",
  [ReportStatus.Resolved]: "Slutförd",
};

export const residentRentalStatusText = {
  [ResidentRentalStatus.Created]: 'Ny',
  [ResidentRentalStatus.Active]: 'Aktiv',
  [ResidentRentalStatus.Upcoming]: 'Kommande',
  [ResidentRentalStatus.Outgoing]: 'Upphörande',
  [ResidentRentalStatus.Inactive]: 'Inaktiv',
}