import { ILoginResponse, IMeResponse } from "../types/auth";
import { ILoginCredentials } from "../types/credentials";
import { ApiRequestMethod, apiRequestService } from "../utils/api";
import { useCallback } from "react";

export const useAuthService = () => {
  const fetchMe = async () =>
    apiRequestService<IMeResponse>({
      method: ApiRequestMethod.Get,
      path: "/me",
    });

  const login = async (credentials: ILoginCredentials) =>
    apiRequestService<ILoginResponse>({
      method: ApiRequestMethod.Post,
      path: "/auth",
      data: credentials,
    });

  return {
    fetchMe: useCallback(fetchMe, []),
    login: useCallback(login, []),
  };
};
