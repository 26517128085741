import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { Column } from "react-table";
import {
  Badge,
  Col,
  Container,
  DropdownItem,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { ActionButton } from "../../components/ActionButton/ActionButton";
import { FoldoutForm } from "../../components/FoldoutForm/FoldoutForm";
import { FastlyTable } from "../../components/Table/FastlyTable";
import { residentRentalStatusColor } from "../../config/colors";
import { residentRentalStatusText } from "../../config/strings";
import { IResident, residentsSelector } from "../../selectors/residents";
import { useRentalService } from "../../services/useRentalService";
import { useResidentService } from "../../services/useResidentService";
import { IResidentResponse } from "../../types/resident";
import { IRequestResponse } from "../../utils/api";

export interface IResidentListPanelProps {
  rentalId: string;
}

export const ResidentListPanel: FunctionComponent<IResidentListPanelProps> = ({
  rentalId,
}) => {
  const history = useHistory();
  const { register, handleSubmit, reset } = useForm();
  const { fetchResidentForRental, createResident } = useResidentService();
  const { removeRentalFromResident, setResidentAsActive } = useRentalService();
  const [residents, setResidents] = useState(({
    data: [],
  } as unknown) as IRequestResponse<IResidentResponse[]>);

  const persons = useMemo(
    () => (residents.success ? residentsSelector(residents.data) : []),
    [residents]
  );

  useEffect(() => {
    (async () => {
      setResidents(await fetchResidentForRental(rentalId));
    })();
  }, [fetchResidentForRental, rentalId]);

  const onTableRowClick = (data: IResident) => {
    history.push(`/residents/${data.id}`);
  };

  const onSubmit = async (data: any) => {
    const createdResident = await createResident(data);

    if (createdResident.success) {
      setResidents({
        ...residents,
        data: [...residents.data, createdResident.data],
      });
      reset();
    }
  };

  const onRemove = async (residentId: string) => {
    const { success } = await removeRentalFromResident(residentId, rentalId);

    if (success) {
      setResidents({
        ...residents,
        data: residents.data.filter((resident) => resident.id !== residentId),
      });
    }
  };

  const onSetActive = async (residentId: string) => {
    const { success } = await setResidentAsActive(rentalId, residentId);

    if (success) {
      setResidents(await fetchResidentForRental(rentalId));
    }
  }

  const personColumns: Column<IResident>[] = [
    {
      accessor: "status",
      Cell: ({ value }) => (
        <Badge color={residentRentalStatusColor[value]}>
          {residentRentalStatusText[value]}
        </Badge>
      ),
    },
    {
      Header: "Namn",
      accessor: "fullname",
    },
    {
      Header: "Telefonnummer",
      accessor: "phone",
    },
    {
      Header: "E-post",
      accessor: "email",
    },
    {
      Header: "Inflyttning",
      accessor: "valid_from",
    },
    {
      Header: "Utflyttning",
      accessor: "valid_to",
    },
    {
      id: "actions",
      accessor: "id",
      Cell: ({ value }) => (
        <ActionButton>
          <DropdownItem onClick={() => onSetActive(value)}>
            Sätt som aktiv
          </DropdownItem>
          <DropdownItem onClick={() => onRemove(value)}>
            Ta bort
          </DropdownItem>
        </ActionButton>
      ),
    },
  ];

  return (
    <>
      <FoldoutForm
        title="Hyreskontrakt"
        buttonText="Byt hyresgäst"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Input
          type="hidden"
          name="rental_id"
          innerRef={register()}
          value={rentalId}
        />
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="personFirstname">Förnamn</Label>
              <Input
                type="text"
                name="firstname"
                id="personFirstname"
                innerRef={register({ required: true })}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="personLastname">Efternamn</Label>
              <Input
                type="text"
                name="lastname"
                id="personLastname"
                innerRef={register({ required: true })}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="personSsn">Personnummer</Label>
              <Input
                type="text"
                name="ssn"
                id="personSsn"
                innerRef={register({ required: true })}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="personPhone">Telefonnummer</Label>
              <Input
                type="text"
                name="phone"
                id="personPhone"
                innerRef={register({ required: true })}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <Label for="personEmail">E-post</Label>
              <Input
                type="email"
                name="email"
                id="personEmail"
                innerRef={register({ required: true })}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="residentPaymentReference">Betalningsreferens</Label>
              <Input
                type="text"
                name="payment_reference"
                id="residentPaymentReference"
                innerRef={register({ required: true })}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <Label for="residentMoveIn">Inflyttningsdatum</Label>
            <Input
              type="date"
              name="valid_from"
              id="residentMoveIn"
              placeholder="Inflyttningsdatum"
              innerRef={register}
            />
          </Col>
        </Row>
      </FoldoutForm>
      <Container>
        <FastlyTable<IResident>
          loaded={residents.loaded}
          columns={personColumns}
          data={persons}
          onTableRowClick={onTableRowClick}
        />
      </Container>
    </>
  );
};
