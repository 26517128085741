export const onEnterKeyPress = (
  func: (e: React.KeyboardEvent<HTMLInputElement>) => void
) => (e: React.KeyboardEvent<HTMLInputElement>) => {
  if (e.charCode === 13) {
    e.stopPropagation();
    func(e);
  }
};

export const removeEmptyFields = (data: { [key: string]: any }) =>
  Object.keys(data).reduce((acc, key) => {
    return {
      ...acc,
      ...(data[key] !== "" && { [key]: data[key] }),
    };
  }, {});
