import React, { useContext } from "react";
import { Collapse, Container, Nav, Navbar, NavbarBrand } from "reactstrap";
import userIcon from "../../assets/images/icons/user.svg";
import logo from "../../assets/images/logo.svg";
import { Icon } from "../Icon/Icon";
import { ApiContext } from "../../context/api";

export const Header = () => {
  const { user } = useContext(ApiContext)

  return (
    <Navbar color="black" dark expand="md" className="mb-5 px-4">
      <Container>
        <NavbarBrand>
          <img
            src={logo}
            height="20"
            className="d-inline-block"
            alt="Fastly logo"
          />
        </NavbarBrand>
        <Collapse navbar>
          <Nav className="mr-auto" navbar></Nav>
        </Collapse>
        <div>
          <span className="mr-2 text-light font-weight-bold">
            { user && user.email }
          </span>
          <Icon image={userIcon} />
        </div>
      </Container>
    </Navbar>
  );
};
