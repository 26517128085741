import React, { FunctionComponent, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Column } from "react-table";
import { Col, Container, FormGroup, Input, Label, Row } from "reactstrap";
import parkingIcon from "../../assets/images/icons/parking.svg";
import { FoldoutForm } from "../../components/FoldoutForm/FoldoutForm";
import { FastlyTable } from "../../components/Table/FastlyTable";
import { useParkingService } from "../../services/useParkingService";
import { IParkingResponse } from "../../types/parking";
import { IParkingTypeResponse } from "../../types/settings";
import { IRequestResponse } from "../../utils/api";

const parkingsColumns: Column<IParkingResponse>[] = [
  {
    Header: "Parkering",
    accessor: "parking_no",
  },
  {
    Header: "Typ",
    accessor: "parking_type",
  },
  {
    Header: "Hyra",
    accessor: "base_rent",
  },
];

export interface IParkingListPanelProps {
  propertyId: string;
}

export const ParkingListPanel: FunctionComponent<IParkingListPanelProps> = ({
  propertyId,
}) => {
  const { register, handleSubmit, reset } = useForm();
  const {
    fetchParkingsForProperty,
    createParkingForProperty,
    fetchParkingTypes,
  } = useParkingService();
  const [parkings, setParkings] = useState({
    loaded: false,
    data: [],
  } as IRequestResponse<IParkingResponse[]>);
  const [parkingTypes, setParkingTypes] = useState({
    loaded: false,
    data: [],
  } as IRequestResponse<IParkingTypeResponse[]>);

  useEffect(() => {
    (async () => {
      setParkings(await fetchParkingsForProperty(propertyId));
    })();
  }, [fetchParkingsForProperty, propertyId]);

  useEffect(() => {
    (async () => {
      setParkingTypes(await fetchParkingTypes());
    })();
  }, [fetchParkingTypes]);

  const createParking = async (data: any) => {
    const { data: newParking } = await createParkingForProperty(
      propertyId,
      data
    );

    setParkings({ loaded: true, data: [...parkings.data, newParking] });
    reset();
  };

  return (
    <>
      <FoldoutForm
        title="Parkeringar"
        buttonText="Lägg till"
        className="mt-5"
        onSubmit={handleSubmit(createParking)}
      >
        <Row>
          <Col>
            <FormGroup>
              <Label for="parkingType">Parkeringstyp</Label>
              <Input
                type="select"
                name="parking_type_id"
                id="parkingType"
                innerRef={register({ required: true })}
              >
                {parkingTypes.data.map(({ id, name }) => (
                  <option key={id} value={id}>
                    {name}
                  </option>
                ))}
              </Input>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label for="parkingNo">Parkeringsnummer</Label>
              <Input
                type="text"
                name="parking_no"
                id="Parking_no"
                innerRef={register({ required: true })}
              />
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label for="parkingBaseRent">Bashyra</Label>
              <Input
                type="text"
                name="base_rent"
                id="parkingBaseRent"
                innerRef={register({ required: true })}
              />
            </FormGroup>
          </Col>
        </Row>
      </FoldoutForm>
      <Container>
        <FastlyTable
          loaded={parkings.loaded}
          columns={parkingsColumns}
          data={parkings.data}
          onTableRowClick={() => {}}
          icon={parkingIcon}
        />
      </Container>
    </>
  );
};
