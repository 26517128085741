import React, { useEffect, useMemo, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import {
  ButtonDropdown,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormGroup,
  Label,
  Row,
} from "reactstrap";
import { LoaderInput } from "../../components/LoaderInput/LoaderInput";
import { reportStatusColor } from "../../config/colors";
import { reportStatusText } from "../../config/strings";
import {
  detailedReportSelector,
  IDetailedReport,
} from "../../selectors/reports";
import { useReportService } from "../../services/useReportService";
import { IDetailedReportResponse, ReportStatus } from "../../types/report";
import { IRequestResponse } from "../../utils/api";
import { PersonListPanel } from "../Panels/PersonListPanel";
import { toYesNo } from "../../utils/fields";

interface ReportParams {
  reportId: string;
}

export const Report: React.FC<RouteComponentProps<ReportParams>> = ({
  match: { params },
}) => {
  const { fetchDetailedReport, patchReportStatus } = useReportService();
  const [statusIsOpen, setStatusIsOpen] = useState(false);
  const [report, setReport] = useState({
    loaded: false,
    data: {},
  } as IRequestResponse<IDetailedReportResponse>);

  useEffect(() => {
    (async () => {
      setReport(await fetchDetailedReport(params.reportId));
    })();
  }, [fetchDetailedReport, params.reportId]);

  const memoizedReport = useMemo(
    () =>
      report.loaded
        ? detailedReportSelector(report.data)
        : ({} as IDetailedReport),
    [report]
  );

  const updateStatus = async (status: ReportStatus) => {
    const { success } = await patchReportStatus(params.reportId, status);

    if (success) {
      setReport({
        loaded: true,
        data: {
          ...report.data,
          status,
        },
      });
    }
  };

  return (
    <>
      <Container>
        <h2>Felanmälan</h2>
        <Form className="mt-5">
          <Row>
            <Col md={8}>
              <FormGroup>
                <Label for="reportObject">Objekt</Label>
                <LoaderInput
                  loaded={report.loaded}
                  defaultValue={memoizedReport.apartment}
                  type="text"
                  name="title"
                  id="reportObject"
                  plaintext
                  disabled
                />
              </FormGroup>
            </Col>
            <Col md={4}>
              <Label>Status</Label>
              <FormGroup>
                <ButtonDropdown
                  isOpen={statusIsOpen}
                  toggle={() => setStatusIsOpen(!statusIsOpen)}
                >
                  <DropdownToggle
                    color={reportStatusColor[memoizedReport.status]}
                    caret
                  >
                    {reportStatusText[memoizedReport.status]}
                  </DropdownToggle>
                  <DropdownMenu>
                    {Object.values(ReportStatus).map((status) => (
                      <DropdownItem
                        key={status}
                        onClick={() => updateStatus(status)}
                        className={`text-${reportStatusColor[status]}`}
                      >
                        {reportStatusText[status]}
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </ButtonDropdown>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <FormGroup>
                <Label>Information</Label>
                <p style={{ whiteSpace: "pre-wrap" }}>
                  {memoizedReport.information}
                </p>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <FormGroup>
                <Label>Ring först</Label>
                <LoaderInput
                  loaded={report.loaded}
                  defaultValue={toYesNo(memoizedReport.call)}
                  type="text"
                  plaintext
                  disabled
                />
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup>
                <Label>Använd huvudnyckel</Label>
                <LoaderInput
                  loaded={report.loaded}
                  defaultValue={toYesNo(memoizedReport.use_key)}
                  type="text"
                  plaintext
                  disabled
                />
              </FormGroup>
            </Col>
          </Row>
        </Form>
      </Container>
      <PersonListPanel
        persons={memoizedReport.persons}
        loaded={report.loaded}
      />
    </>
  );
};
