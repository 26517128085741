import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { Column } from "react-table";
import { Col, Container, FormGroup, Input, Label, Row } from "reactstrap";
import apartmentIcon from "../../assets/images/icons/apartment.svg";
import { FoldoutForm } from "../../components/FoldoutForm/FoldoutForm";
import { FastlyTable } from "../../components/Table/FastlyTable";
import { useApartmentService } from "../../services/useApartmentService";
import { useSettingService } from "../../services/useSettingService";
import { IApartmentResponse } from "../../types/apartment";
import { IRoomTemplateResponse } from "../../types/settings";
import { IRequestResponse } from "../../utils/api";

const apartmentsColumns: Column<IApartmentResponse>[] = [
  {
    Header: "Lägenhet",
    accessor: "slug",
  },
  {
    Header: "Grundhyra",
    accessor: "base_rent",
  },
  {
    Header: "Status",
    accessor: "status",
  },
  {
    Header: "Area",
    accessor: "area",
  },
];

export interface IApartmentListPanelProps {
  buildingId: string;
}

export const ApartmentListPanel: React.FC<IApartmentListPanelProps> = ({
  buildingId,
}) => {
  const history = useHistory();
  const { register, handleSubmit } = useForm();
  const {
    fetchApartmentsForBuilding,
    createApartmentForBuilding,
  } = useApartmentService();
  const { fetchRoomTemplates } = useSettingService();
  const [apartments, setApartments] = useState({
    loaded: false,
    data: [],
  } as IRequestResponse<IApartmentResponse[]>);
  const [roomTemplates, setRoomTemplates] = useState(({
    data: [],
  } as unknown) as IRequestResponse<IRoomTemplateResponse[]>);

  useEffect(() => {
    (async () => {
      setApartments(await fetchApartmentsForBuilding(buildingId));
    })();
  }, [buildingId, fetchApartmentsForBuilding]);

  useEffect(() => {
    (async () => {
      setRoomTemplates(await fetchRoomTemplates());
    })();
  }, [fetchRoomTemplates]);

  const createApartment = async (data: any) => {
    const { data: newApartment } = await createApartmentForBuilding(
      buildingId,
      data
    );

    setApartments({ loaded: true, data: [...apartments.data, newApartment] });
  };

  const onTableRowClick = (data: any) => {
    history.push(`/apartments/${data.id}`);
  };

  return (
    <>
      <FoldoutForm
        title="Lägenheter"
        buttonText="Lägg till"
        onSubmit={handleSubmit(createApartment)}
      >
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="apartmentNo">Lägenhetsnummer</Label>
              <Input
                type="text"
                name="apartment_no"
                id="apartmentNo"
                innerRef={register}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="apartmentBaseRent">Grundhyra</Label>
              <Input
                type="text"
                name="base_rent"
                id="apartmentBaseRent"
                innerRef={register}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="apartmentArea">Area</Label>
              <Input
                type="text"
                name="area"
                id="apartmentArea"
                innerRef={register}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="apartmentRoomTemplate">Lägenhetsmall</Label>
              <Input
                type="select"
                name="room_template_id"
                id="apartmentRoomTemplate"
                innerRef={register({ required: true })}
              >
                {roomTemplates.data.map(({ id, name }) => (
                  <option key={id} value={id}>
                    {name}
                  </option>
                ))}
              </Input>
            </FormGroup>
          </Col>
        </Row>
      </FoldoutForm>
      <Container>
        <FastlyTable<IApartmentResponse>
          loaded={apartments.loaded}
          columns={apartmentsColumns}
          data={apartments.data}
          onTableRowClick={onTableRowClick}
          icon={apartmentIcon}
        />
      </Container>
    </>
  );
};
