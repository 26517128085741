import QRCode from "qrcode.react";
import React, { FunctionComponent, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { Col, FormGroup, Label, Row } from "reactstrap";
import { LoaderInput } from "../../components/LoaderInput/LoaderInput";
import { ResourceForm } from "../../components/ResourceForm/ResourceForm";
import { useApartmentService } from "../../services/useApartmentService";
import { useSettingService } from "../../services/useSettingService";
import { IDetailedApartmentResponse } from "../../types/apartment";
import { IRoomTemplateResponse } from "../../types/settings";
import { IRequestResponse } from "../../utils/api";
import { NotifyPanel } from "../Panels/NotifyPanel";
import { ResidentListPanel } from "../Panels/ResidentListPlanel";

interface ApartmentParams {
  apartmentId: string;
}

export const Apartment: FunctionComponent<RouteComponentProps<
  ApartmentParams
>> = ({ match: { params } }) => {
  const { register, handleSubmit } = useForm();
  const history = useHistory();
  const {
    fetchDetailedApartment,
    updateApartment,
    deleteApartment,
  } = useApartmentService();
  const { fetchRoomTemplates } = useSettingService();
  const [apartment, setApartment] = useState({
    loaded: false,
    data: {},
  } as IRequestResponse<IDetailedApartmentResponse>);
  const [roomTemplates, setRoomTemplates] = useState(({
    data: [],
  } as unknown) as IRequestResponse<IRoomTemplateResponse[]>);

  useEffect(() => {
    (async () => {
      setApartment(await fetchDetailedApartment(params.apartmentId));
    })();
  }, [fetchDetailedApartment, params.apartmentId]);

  useEffect(() => {
    (async () => {
      setRoomTemplates(await fetchRoomTemplates());
    })();
  }, [fetchRoomTemplates]);

  const onSubmit = (data: any) => {
    updateApartment(params.apartmentId, data);
  };

  const onDelete = async () => {
    const response = await deleteApartment(params.apartmentId);

    if (response.success) {
      history.goBack();
    }
  };

  return (
    <>
      <ResourceForm
        title={apartment.data.slug}
        onSubmit={handleSubmit(onSubmit)}
        onDelete={onDelete}
      >
        <Row className="align-items-center">
          <Col>
            <Row>
              <Col md={3}>
                <Row md="auto">
                  <Col>
                    <FormGroup>
                      <Label>QR-kod</Label>
                      <div>
                        {apartment.loaded && <QRCode
                          value={apartment.data.login_url}
                          size={128}
                          renderAs="svg"
                        />}
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
              <Col md={9}>
                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <Label for="apartmentTitle">Lägenhetsnummer</Label>
                      <LoaderInput
                        loaded={apartment.loaded}
                        defaultValue={apartment.data.apartment_no}
                        type="text"
                        name="apartment_no"
                        id="propertyApartmentno"
                        innerRef={register}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label for="apartmentBaseRent">Grundhyra</Label>
                      <LoaderInput
                        loaded={apartment.loaded}
                        defaultValue={apartment.data.base_rent}
                        type="text"
                        name="base_rent"
                        id="apartmentBaseRent"
                        innerRef={register}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <FormGroup>
                      <Label for="apartmentRoomTemplate">Lägenhetsmall</Label>
                      <LoaderInput
                        loaded={apartment.loaded}
                        type="select"
                        name="room_template_id"
                        id="apartmentRoomTemplate"
                        defaultValue={apartment.data.room_template_id}
                        innerRef={register({ required: true })}
                      >
                        {roomTemplates.data.map(({ id, name }) => (
                          <option key={id} value={id}>
                            {name}
                          </option>
                        ))}
                      </LoaderInput>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <Label for="apartmentArea">Area</Label>
                      <LoaderInput
                        loaded={apartment.loaded}
                        defaultValue={apartment.data.area}
                        type="text"
                        name="area"
                        id="apartmentArea"
                        innerRef={register}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label for="apartmentPassword">Lösenord</Label>
                      <LoaderInput
                        loaded={apartment.loaded}
                        defaultValue={apartment.data.password}
                        disabled
                        type="text"
                        name="password"
                        id="apartmentPassword"
                        innerRef={register}
                        plaintext
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </ResourceForm>
      <ResidentListPanel rentalId={params.apartmentId} />
      {apartment.data.unoccupied && (
        <NotifyPanel apartmentId={params.apartmentId} />
      )}
    </>
  );
};
