import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { Column } from "react-table";
import { Container, DropdownItem, Input } from "reactstrap";
import applicationIcon from "../../assets/images/icons/speculant.svg";
import { ActionButton } from "../../components/ActionButton/ActionButton";
import { Paginator } from "../../components/Paginator/Paginator";
import { FastlyTable } from "../../components/Table/FastlyTable";
import {
  applicationSelector,
  IApplication,
} from "../../selectors/applications";
import { useApplicationService } from "../../services/useApplicationService";
import { IApplicationResponsePaginated } from "../../types/application";
import { IRequestResponse } from "../../utils/api";

interface IOpts {
  name?: string;
  offset?: number;
}

export const ApplicationListPanel = () => {
  const history = useHistory();
  const { fetchApplications, deleteApplication, prioritizeApplication } =
    useApplicationService();
  const [opts, setOpts] = useState<IOpts>({ offset: 0 });
  const [applications, setApplications] = useState({
    loaded: false,
    data: { applications: [], total_count: 0 },
  } as IRequestResponse<IApplicationResponsePaginated>);

  const memoizedApplications = useMemo(
    () =>
      applications.data.applications.map((application, index) =>
        applicationSelector({
          ...application,
          queue: index + (opts.offset ?? 0) + 1,
        })
      ),
    [applications.data.applications, opts.offset]
  );

  useEffect(() => {
    (async () => {
      setApplications(await fetchApplications(opts));
    })();
  }, [fetchApplications, opts]);

  const onTableRowClick = (data: any) => {
    history.push(`/applications/${data.id}`);
  };

  const onDeleteClick = async (id: string) => {
    const { success } = await deleteApplication(id);

    if (success) {
      setApplications({
        ...applications,
        data: {
          total_count: applications.data.total_count - 1,
          applications: applications.data.applications.filter(
            (application) => application.id !== id
          ),
        },
      });
    }
  };

  const onPrioritizeClick = async (id: string) => {
    const firstDateInQueue = new Date(memoizedApplications[0].date);
    const updatedDate = new Date(
      firstDateInQueue.getTime() - 1000 * 60 * 60 * 24
    )
      .toISOString()
      .slice(0, 10);

    const { success } = await prioritizeApplication(id, updatedDate);

    if (success) {
      setApplications(await fetchApplications(opts));
    }
  };

  const onFilterChange = async ({ name, offset }: IOpts) => {
    if (name !== undefined && (name.length >= 3 || name === "")) {
      setOpts((o) => ({ ...o, name }));
    }

    if (offset !== undefined && offset >= 0) {
      setOpts((o) => ({ ...o, offset }));
    }
  };

  const applicationColumns: Column<IApplication>[] = [
    {
      Header: "Köplats",
      accessor: "queue",
    },
    {
      Header: "Namn",
      accessor: "fullname",
    },
    {
      Header: "Lägenhetstyp",
      accessor: "apartmentType",
    },
    {
      Header: "Område",
      accessor: "area",
    },
    {
      Header: "Datum",
      accessor: "date",
    },
    {
      id: "actions",
      accessor: "id",
      Cell: ({ value }) => (
        <ActionButton>
          <DropdownItem onClick={() => onPrioritizeClick(value)}>
            Sätt först i kön
          </DropdownItem>
          <DropdownItem onClick={() => onDeleteClick(value)}>
            Ta bort
          </DropdownItem>
        </ActionButton>
      ),
    },
  ];

  return (
    <Container className="mt-5">
      <h2 className="mb-4">
        Intresseanmälningar ({applications.data.total_count})
      </h2>
      <Input
        placeholder="Sök"
        onChange={(e) => onFilterChange({ name: e.target.value })}
        type="text"
      />
      <FastlyTable<IApplication>
        loaded={applications.loaded}
        columns={applicationColumns}
        data={memoizedApplications}
        filter={false}
        onTableRowClick={onTableRowClick}
        icon={applicationIcon}
        initialState={{ pageSize: 20 }}
      />

      <Paginator
        loaded={applications.loaded}
        offset={opts.offset}
        pageSize={20}
        totalCount={applications.data.total_count}
        setOffset={(offset) => onFilterChange({ offset })}
      />
    </Container>
  );
};
