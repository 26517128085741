import { ReportStatus } from "../types/report";
import { ResidentRentalStatus } from "../types/resident";

export const reportStatusColor = {
  [ReportStatus.Open]: "danger",
  [ReportStatus.InProgress]: "warning",
  [ReportStatus.Resolved]: "success",
}

export const residentRentalStatusColor = {
  [ResidentRentalStatus.Created]: 'success',
  [ResidentRentalStatus.Active]: 'primary',
  [ResidentRentalStatus.Upcoming]: 'info',
  [ResidentRentalStatus.Outgoing]: 'warning',
  [ResidentRentalStatus.Inactive]: 'danger',
}