import React, { useState } from "react";
import {
  Card,
  CardText,
  CardTitle,
  Container,
  Collapse,
  Alert,
} from "reactstrap";
import {
  IRecentResponse,
  IRecentApplicationResponse,
  RecentType,
  IRecentReportResponse,
} from "../../types/overview";
import { Icon } from "../Icon/Icon";
import damagesIcon from "../../assets/images/icons/damages.svg";
import speculantIcon from "../../assets/images/icons/speculant.svg";
import { toPrettyDate } from "../../utils/fields";
import { Loader } from "../Loader/Loader";

export interface IRecentFeedProp {
  loaded: boolean;
  recent: IRecentResponse[];
}

const ApplicationCard: React.FC<{
  application: IRecentApplicationResponse;
}> = ({ application }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Card
      color="muted"
      className="mb-3"
      body
      onClick={() => setIsOpen(!isOpen)}
    >
      <CardTitle>
        <span className="mr-3">
          <Icon image={speculantIcon} />
        </span>
        {`${application.name} har gjort en intresseanmälan`}
      </CardTitle>
      <Collapse isOpen={isOpen}>
        <CardText className="small mb-3">{application.information}</CardText>
      </Collapse>
      <CardText className="small text-muted">
        {toPrettyDate(application.date)}
      </CardText>
    </Card>
  );
};

const ReportCard: React.FC<{ report: IRecentReportResponse }> = ({
  report,
}) => (
  <Card color="muted" className="mb-3" body>
    <CardTitle>
      <span className="mr-3">
        <Icon image={damagesIcon} />
      </span>
      {`Felanmälan för ${report.rental.slug}`}
    </CardTitle>
    <CardText className="small">{report.information}</CardText>
    <CardText className="small text-muted">
      {toPrettyDate(report.date)}
    </CardText>
  </Card>
);

export const RecentFeed: React.FC<IRecentFeedProp> = ({ loaded, recent }) => {
  return (
    <Container className="mt-5">
      <h2 className="mb-5">Händelser</h2>
      {loaded ? (
        recent.length > 0 ? (
          recent
            .sort((a, b) => (a.date < b.date ? 1 : -1))
            .map((item) =>
              item.type === RecentType.Application ? (
                <ApplicationCard
                  application={(item as unknown) as IRecentApplicationResponse}
                />
              ) : (
                <ReportCard
                  report={(item as unknown) as IRecentReportResponse}
                />
              )
            )
        ) : (
          <Alert color="info">Det finns inga nya händelser att visa</Alert>
        )
      ) : (
        <Loader />
      )}
    </Container>
  );
};
