import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { Column } from "react-table";
import {
  Badge,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import residentIcon from "../../assets/images/icons/person.svg";
import { FoldoutForm } from "../../components/FoldoutForm/FoldoutForm";
import { FastlyTable } from "../../components/Table/FastlyTable";
import { IResident, residentsSelector } from "../../selectors/residents";
import { useRentalService } from "../../services/useRentalService";
import { useResidentService } from "../../services/useResidentService";
import { IRentalResponse } from "../../types/rental";
import { IResidentResponse, ResidentRentalStatus } from "../../types/resident";
import { IRequestResponse } from "../../utils/api";

const residentsColumns: Column<IResident>[] = [
  {
    Header: "Namn",
    accessor: "fullname",
  },
  {
    Header: "Telefonnummer",
    accessor: "phone",
  },
  {
    Header: "E-post",
    accessor: "email",
  },
  {
    Header: "Status",
    accessor: "status",
    Cell: ({ row }) =>
      row.values.status === ResidentRentalStatus.Created ? (
        <Badge color="success">Ny</Badge>
      ) : row.values.status === ResidentRentalStatus.Active ? (
        <Badge color="info">Aktiv</Badge>
      ) : (
        <Badge color="danger">Arkiverad</Badge>
      ),
  },
  {
    id: "order",
    accessor: (d) => Object.values(ResidentRentalStatus).indexOf(d.status),
  },
];

const initialState = {
  loaded: false,
  data: [],
};

export const Residents = () => {
  const history = useHistory();
  const { handleSubmit, register, reset } = useForm();
  const { fetchResidents, createResident } = useResidentService();
  const { fetchAvailableRentals } = useRentalService();
  const [residents, setResidents] = useState<
    IRequestResponse<IResidentResponse[]>
  >(initialState);
  const [availableRentals, setAvailableRentals] = useState<
    IRequestResponse<IRentalResponse[]>
  >(initialState);

  useEffect(() => {
    (async () => {
      setResidents(await fetchResidents());
    })();
  }, [fetchResidents]);

  const persons = useMemo(() => residentsSelector(residents.data), [
    residents.data,
  ]);

  const onExpand = async () => {
    setAvailableRentals(await fetchAvailableRentals());
  };

  const onTableRowClick = (data: any) => {
    history.push(`/residents/${data.id}`);
  };

  const onSubmit = async (data: any) => {
    const createdResident = await createResident(data);

    setResidents({
      loaded: true,
      data: [createdResident.data, ...residents.data],
    });

    reset();
  };

  return (
    <Container>
      <FoldoutForm
        title="Hyreskontrakt"
        buttonText="Lägg till"
        onSubmit={handleSubmit(onSubmit)}
        onExpand={onExpand}
      >
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="personFirstname">Förnamn</Label>
              <Input
                type="text"
                name="firstname"
                id="personFirstname"
                innerRef={register({ required: true })}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="personLastname">Efternamn</Label>
              <Input
                type="text"
                name="lastname"
                id="personLastname"
                innerRef={register({ required: true })}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="personSsn">Personnummer</Label>
              <Input
                type="text"
                name="ssn"
                id="personSsn"
                innerRef={register({ required: true })}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="personPhone">Telefonnummer</Label>
              <Input
                type="text"
                name="phone"
                id="personPhone"
                innerRef={register({ required: true })}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <Label for="personEmail">E-post</Label>
              <Input
                type="email"
                name="email"
                id="personEmail"
                innerRef={register({ required: true })}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="personFirstname">Betalningsreferens</Label>
              <Input
                type="text"
                name="payment_reference"
                id="personFirstname"
                innerRef={register({ required: true })}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="residentRentalId">Hyresobjekt</Label>
              <Input
                type="select"
                name="rental_id"
                id="residentRentalId"
                innerRef={register({ required: true })}
              >
                {availableRentals.data.map(({ id, slug }) => (
                  <option key={id} value={id}>
                    {slug}
                  </option>
                ))}
              </Input>
            </FormGroup>
          </Col>
        </Row>
      </FoldoutForm>
      <FastlyTable<IResident>
        loaded={residents.loaded}
        columns={residentsColumns}
        data={persons}
        initialState={{
          sortBy: [{ id: "order" }, { id: "fullname" }],
          hiddenColumns: ["order"],
        }}
        onTableRowClick={onTableRowClick}
        icon={residentIcon}
      />
    </Container>
  );
};
