import React from "react";
import { ApplicationListPanel } from "../Panels/ApplicationListPanel";
import { NotificationListPanel } from "../Panels/NotificationListPanel";

export const Applications = () => {
  return (
    <div>
      <NotificationListPanel />
      <ApplicationListPanel />
    </div>
  );
};
