import { useContext, useCallback } from "react";
import { ApiContext } from "../context/api";
import {
  IDetailedPropertyResponse,
  IPropertyResponse,
} from "../types/property";
import { ApiRequestMethod, clientApiRequestService } from "../utils/api";

export const usePropertyService = () => {
  const { client } = useContext(ApiContext);

  const fetchProperties = () =>
    clientApiRequestService<IPropertyResponse[]>({
      client,
      method: ApiRequestMethod.Get,
      path: "/properties",
    });

  const fetchDetailedProperty = (propertyId: string) =>
    clientApiRequestService<IDetailedPropertyResponse>({
      client,
      method: ApiRequestMethod.Get,
      path: `/properties/${propertyId}`,
    });

  const createProperty = (data: any) =>
    clientApiRequestService<IPropertyResponse>({
      client,
      method: ApiRequestMethod.Post,
      path: "/properties",
      data,
    });

  const updateProperty = (propertyId: string, data: any) =>
    clientApiRequestService<IPropertyResponse>({
      client,
      method: ApiRequestMethod.Put,
      path: `/properties/${propertyId}`,
      data,
    });

  const deleteProperty = (propertyId: string) =>
    clientApiRequestService({
      client,
      method: ApiRequestMethod.Delete,
      path: `/properties/${propertyId}`,
    });

  return {
    fetchProperties: useCallback(fetchProperties, []),
    fetchDetailedProperty: useCallback(fetchDetailedProperty, []),
    createProperty: useCallback(createProperty, []),
    updateProperty: useCallback(updateProperty, []),
    deleteProperty: useCallback(deleteProperty, []),
  };
};
