import React, { FunctionComponent, useState } from "react";
import {
  Button,
  ButtonDropdown,
  Col,
  Collapse,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormGroup,
  Row,
} from "reactstrap";

export interface ResourceFormProps {
  title: string;
  onSubmit?: (data: any) => void;
  onDelete?: () => void;
}

export const ResourceForm: FunctionComponent<ResourceFormProps> = ({
  title,
  children,
  onSubmit,
  onDelete,
}) => {
  const [disabled, setDisabled] = useState(true);
  const [deleteIsExpanded, setDeleteIsExpanded] = useState(false);

  const handleSubmit = (data: any) => {
    setDisabled(true);
    onSubmit && onSubmit(data);
  };

  return (
    <div className="mb-5">
      <Container>
        <Row className="mb-5">
          <Col sm={8}>
            <h2>{title}</h2>
          </Col>
          <Col sm={4}>
            <Button
              color="black"
              className="float-right"
              onClick={() => setDisabled(!disabled)}
            >
              {disabled ? "Redigera" : "Avbryt"}
            </Button>
          </Col>
        </Row>
        <Form onSubmit={handleSubmit}>
          <FormGroup tag="fieldset" disabled={disabled}>
            {children}
          </FormGroup>
          <Collapse className="mt-4" isOpen={!disabled}>
            <Row>
              <Col className="mr-auto" xs="auto">
                <Button color="black" onClick={() => setDisabled(true)}>
                  Avbryt
                </Button>
              </Col>
              <Col xs={"auto"}>
                {onDelete && (
                  <ButtonDropdown
                    isOpen={deleteIsExpanded}
                    toggle={() => setDeleteIsExpanded(!deleteIsExpanded)}
                  >
                    <DropdownToggle color="danger" outline>
                      Ta bort
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem onClick={onDelete}>Bekräfta</DropdownItem>
                      <DropdownItem>Avbryt</DropdownItem>
                    </DropdownMenu>
                  </ButtonDropdown>
                )}
              </Col>
              <Col xs={"auto"}>
                {onSubmit && <Button color="success">Spara</Button>}
              </Col>
            </Row>
          </Collapse>
        </Form>
      </Container>
    </div>
  );
};
