import React, { FunctionComponent, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { Column } from "react-table";
import { Container, FormGroup, Input, Label } from "reactstrap";
import buildingIcon from "../../assets/images/icons/building.svg";
import { FoldoutForm } from "../../components/FoldoutForm/FoldoutForm";
import { FastlyTable } from "../../components/Table/FastlyTable";
import { useBuildingService } from "../../services/useBuildingService";
import { IBuildingResponse } from "../../types/building";
import { IRequestResponse } from "../../utils/api";

const buildingsColumns: Column<IBuildingResponse>[] = [
  {
    Header: "Adress",
    accessor: "address",
  },
  {
    Header: "Antal hyresobjekt",
    accessor: "rental_count",
  },
];

export interface IBuildingListPanelProps {
  propertyId: string;
}

export const BuildingListPanel: FunctionComponent<IBuildingListPanelProps> = ({
  propertyId,
}) => {
  const history = useHistory();
  const { register, handleSubmit } = useForm();
  const {
    fetchBuildingsForProperty,
    createBuildingForProperty,
  } = useBuildingService();
  const [buildings, setBuildings] = useState({
    loaded: false,
    data: [],
  } as IRequestResponse<IBuildingResponse[]>);

  useEffect(() => {
    (async () => {
      setBuildings(await fetchBuildingsForProperty(propertyId));
    })();
  }, [fetchBuildingsForProperty, propertyId]);

  const createBuilding = async (data: any) => {
    const { data: createdBuilding } = await createBuildingForProperty(
      propertyId,
      data
    );

    setBuildings({ loaded: true, data: [createdBuilding, ...buildings.data] });
  };

  const onTableRowClick = (data: any) => {
    history.push(`/buildings/${data.id}`);
  };

  return (
    <>
      <FoldoutForm
        title="Byggnader"
        buttonText="Lägg till"
        onSubmit={handleSubmit(createBuilding)}
      >
        <FormGroup>
          <Label for="buildingAddress">Adress</Label>
          <Input
            type="text"
            name="address"
            id="buildingAddress"
            innerRef={register({ required: true })}
          />
        </FormGroup>
      </FoldoutForm>
      <Container>
        <FastlyTable<IBuildingResponse>
          loaded={buildings.loaded}
          columns={buildingsColumns}
          data={buildings.data}
          onTableRowClick={onTableRowClick}
          icon={buildingIcon}
        />
      </Container>
    </>
  );
};
