import React, { useEffect, useState } from "react";
import { ApiContext } from "../../context/api";
import { useAuthService } from "../../services/useAuthService";

export const ApiContextProvider: React.FC = ({ children }) => {
  const { fetchMe } = useAuthService();
  const [isLoaded, setIsLoaded] = useState(false);
  const [client, setClient] = useState();
  const [user, setUser] = useState();

  useEffect(() => {
    (async () => {
      try {
        const { success, data } = await fetchMe();

        if (success) {
          setUser(data);
          setClient(data.clients[0].id);
        }
      } finally {
        setIsLoaded(true);
      }
    })();
  }, [fetchMe]);

  return (
    <ApiContext.Provider
      value={{
        loaded: isLoaded,
        client,
        user,
      }}
    >
      {children}
    </ApiContext.Provider>
  );
};
