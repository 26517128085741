export const modify = <T>(
  predicate: (value: T) => boolean,
  modifier: (value: T) => void,
  array: T[]
) => {
  const item = array.find(predicate);

  if (item) {
    modifier(item);
  }

  return array;
};

export const range = (start: number, end: number) =>
  [...Array(end - start)].map((_, index) => index + start);
