import React, { FunctionComponent } from "react";
import "./Icon.scss";

export interface IIconProps {
  image: string;
  id?: string;
  alt?: string;
  height?: string;
}

export const Icon: FunctionComponent<IIconProps> = ({
  image,
  alt,
  height,
  ...rest
}) => (
  <img
    src={image}
    height={height}
    className="d-inline-block align-icon"
    alt={alt}
    {...rest}
  />
);
