import { useContext, useCallback } from "react";
import { ApiContext } from "../context/api";
import { IAddonResponse } from "../types/addon";
import { ApiRequestMethod, clientApiRequestService } from "../utils/api";

export const useAddonService = () => {
  const { client } = useContext(ApiContext);

  const fetchAddonTypes = () =>
    clientApiRequestService<IAddonResponse[]>({
      client,
      method: ApiRequestMethod.Get,
      path: "/costs",
    });

  const updateAddonType = (addonId: string, data: any) =>
    clientApiRequestService({
      client,
      method: ApiRequestMethod.Put,
      path: `/costs/${addonId}`,
      data,
    });

  const addCostToSpace = (spaceId: string, costId: string) =>
    clientApiRequestService<any>({
      client,
      method: ApiRequestMethod.Post,
      path: `/spaces/${spaceId}/costs/${costId}`,
    });

  const createAddonType = (data: any) =>
    clientApiRequestService<IAddonResponse>({
      client,
      method: ApiRequestMethod.Post,
      path: "/costs",
      data,
    });

  return {
    fetchAddonTypes: useCallback(fetchAddonTypes, []),
    updateAddonType: useCallback(updateAddonType, []),
    addCostToSpace: useCallback(addCostToSpace, []),
    createAddonType: useCallback(createAddonType, []),
  };
};
