import {
  IApplicationByApartmentResponse,
  IApplicationResponse,
} from "../types/application";
import { toPrettyDate } from "../utils/fields";

export interface IApplication {
  id: string;
  queue: number;
  fullname: string;
  apartmentType: string;
  area: string;
  date: string;
}

export const applicationSelector = ({
  id,
  queue,
  person,
  apartment_types,
  areas,
  date,
}: IApplicationResponse & { queue: number }): IApplication => ({
  id,
  queue,
  fullname: person.fullname,
  apartmentType: apartment_types.map((type) => type.abbrevation).join(", "),
  area: areas.map((area) => area.name).join(", "),
  date: toPrettyDate(date),
});

export interface IApplicationByApartment {
  id: string;
  fullname: string;
  smoker: string;
  pets: string;
  date: string;
}

export const applicationByApartmentSelector = (
  applications: IApplicationByApartmentResponse[]
): IApplicationByApartment[] =>
  applications.map(({ id, person, smoker, pets, date }) => ({
    id,
    smoker: smoker ? "Ja" : "Nej",
    pets: pets ? "Ja" : "Nej",
    fullname: person.fullname,
    date: toPrettyDate(date),
  }));
