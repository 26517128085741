import { useCallback, useContext } from "react";
import { ApiContext } from "../context/api";
import {
  IBuildingResponse,
  IDetailedBuildingResponse,
} from "../types/building";
import { ApiRequestMethod, clientApiRequestService } from "../utils/api";

export const useBuildingService = () => {
  const { client } = useContext(ApiContext);

  const fetchBuildings = () =>
    clientApiRequestService<IBuildingResponse[]>({
      client,
      method: ApiRequestMethod.Get,
      path: "/buildings",
    });

  const fetchDetailedBuilding = (buildingId: string) =>
    clientApiRequestService<IDetailedBuildingResponse>({
      client,
      method: ApiRequestMethod.Get,
      path: `/buildings/${buildingId}`,
    });

  const fetchBuildingsForProperty = (propertyId: string) =>
    clientApiRequestService<IBuildingResponse[]>({
      client,
      method: ApiRequestMethod.Get,
      path: `/properties/${propertyId}/buildings`,
    });

  const createBuildingForProperty = (propertyId: string, data: any) =>
    clientApiRequestService<IBuildingResponse>({
      client,
      method: ApiRequestMethod.Post,
      path: `/properties/${propertyId}/buildings`,
      data,
    });

  const updateBuilding = (buildingId: string, data: any) =>
    clientApiRequestService<IDetailedBuildingResponse>({
      client,
      method: ApiRequestMethod.Put,
      path: `/buildings/${buildingId}`,
      data,
    });

  const deleteBuilding = (buildingId: string) =>
    clientApiRequestService({
      client,
      method: ApiRequestMethod.Delete,
      path: `/buildings/${buildingId}`,
    });

  return {
    fetchBuildings: useCallback(fetchBuildings, []),
    fetchDetailedBuilding: useCallback(fetchDetailedBuilding, []),
    fetchBuildingsForProperty: useCallback(fetchBuildingsForProperty, []),
    createBuildingForProperty: useCallback(createBuildingForProperty, []),
    updateBuilding: useCallback(updateBuilding, []),
    deleteBuilding: useCallback(deleteBuilding, []),
  };
};
