import csv from "csvtojson";

export const readFileAsJson = (file: Blob) =>
  new Promise((resolve) => {
    const fileReader = new FileReader();

    fileReader.onload = async () => {
      const json = await csv().fromString(fileReader.result as string);

      resolve(json);
    };

    return fileReader.readAsText(file);
  });
