import {
  IDetailedReportResponse,
  IReportResponse,
  ReportStatus,
} from "../types/report";
import { toPrettyDate } from "../utils/fields";

export interface IReport {
  id: string;
  apartment: string;
  information: string;
  status: ReportStatus;
  date: string;
}

export interface IDetailedReport extends IReport {
  call: boolean;
  use_key: boolean;
  persons: {
    fullname: string;
    email: string;
    phone: string;
  }[];
}

export const reportSelector = ({
  id,
  rental,
  information,
  status,
  date,
}: IReportResponse): IReport => ({
  id,
  apartment: rental.slug,
  information,
  status,
  date: toPrettyDate(date),
});

export const detailedReportSelector = ({
  id,
  rental,
  information,
  call,
  use_key,
  status,
  date,
  persons,
}: IDetailedReportResponse): IDetailedReport => ({
  id,
  apartment: rental.slug,
  call,
  use_key,
  information,
  status,
  date: toPrettyDate(date),
  persons,
});
