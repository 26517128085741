import React, { FunctionComponent, useEffect, useState } from "react";
import {
  Button,
  Col,
  Collapse,
  Container,
  ContainerProps,
  Form,
  Row,
} from "reactstrap";
import Add from "../../assets/images/icons/add.svg";
import { Icon } from "../Icon/Icon";

interface FoldoutFormProps extends ContainerProps {
  title: string;
  buttonText: string;
  onSubmit?: (event: React.FormEvent<HTMLFormElement>) => void;
  onExpand?: () => void;
  children?: any;
}

export const FoldoutForm: FunctionComponent<FoldoutFormProps> = ({
  title,
  buttonText,
  onSubmit,
  onExpand = () => {},
  children,
  ...rest
}: FoldoutFormProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [hasExpanded, setHasExpanded] = useState(false);

  useEffect(() => {
    if (!hasExpanded && isExpanded) {
      onExpand();
      setHasExpanded(true);
    }
  }, [hasExpanded, isExpanded, onExpand]);

  const toggle = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <Container {...rest}>
      <Row className="mb-4">
        <Col>
          <h3>{title}</h3>
        </Col>
        <Col>
          {onSubmit && (
            <Button color="beige" className="float-right" onClick={toggle}>
              <Icon image={Add} />
            </Button>
          )}
        </Col>
      </Row>

      <Collapse className="mb-5 p-3 mb-5 bg-beige rounded" isOpen={isExpanded}>
        <Form onSubmit={onSubmit}>
          {children}
          <Row form>
            <Col xs={6}>
              <Button color="black" onClick={toggle}>
                Avbryt
              </Button>
            </Col>
            <Col xs={6}>
              <Button type="submit" className="float-right" color="red">
                Spara
              </Button>
            </Col>
          </Row>
        </Form>
      </Collapse>
    </Container>
  );
};
