import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { Column } from "react-table";
import { Container, Badge } from "reactstrap";
import residentIcon from "../../assets/images/icons/person.svg";
import { FastlyTable } from "../../components/Table/FastlyTable";
import { IReport, reportSelector } from "../../selectors/reports";
import { useReportService } from "../../services/useReportService";
import { IReportResponse, ReportStatus } from "../../types/report";
import { IRequestResponse } from "../../utils/api";
import { reportStatusText } from "../../config/strings";

const statusBadge = {
  [ReportStatus.Open]: (
    <Badge color="danger">{reportStatusText[ReportStatus.Open]}</Badge>
  ),
  [ReportStatus.InProgress]: (
    <Badge color="warning">{reportStatusText[ReportStatus.InProgress]}</Badge>
  ),
  [ReportStatus.Resolved]: (
    <Badge color="success">{reportStatusText[ReportStatus.Resolved]}</Badge>
  ),
};

const reportColumns: Column<IReport>[] = [
  {
    Header: "Lägenhet",
    accessor: "apartment",
  },
  {
    Header: "Datum",
    accessor: "date",
  },
  {
    Header: "Status",
    id: "status",
    accessor: "status",
    Cell: ({ row }) => statusBadge[row.original.status],
  },
];

export const Reports = () => {
  const { fetchReports } = useReportService();
  const history = useHistory();
  const [reports, setReports] = useState({
    loaded: false,
    data: [],
  } as IRequestResponse<IReportResponse[]>);

  const memoizedReports = useMemo(() => reports.data.map(reportSelector), [
    reports,
  ]);

  useEffect(() => {
    (async () => {
      setReports(await fetchReports());
    })();
  }, [fetchReports]);

  const onTableRowClick = (data: any) => {
    history.push(`/reports/${data.id}`);
  };

  return (
    <Container>
      <h2 className="mb-4">Felanmälningar</h2>
      <FastlyTable<IReport>
        loaded={reports.loaded}
        columns={reportColumns}
        data={memoizedReports}
        icon={residentIcon}
        onTableRowClick={onTableRowClick}
      />
    </Container>
  );
};
